import React, { useEffect, useState } from "react";
import TimeEstimatesLayout from "./TimeEstimatesLayout";
import TimeEstimatesMenu from "./TimeEstimatesMenu";
import { useGetTenantProjects } from "../../Core/redux/ReactQueryHooksV3/userTimeEstimatesAPI";

import { ProjectCount, ProjectContainer } from "./TimeEstimatesReusable";

const TimeEstimatesScreen = () => {
  const { data: projectsData } = useGetTenantProjects();
  const projects = projectsData?.projects || [];

  const [search, setSearch] = useState("");
  const filteredProjects = projects.filter((project) =>
    project.title.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <TimeEstimatesLayout>
      <TimeEstimatesMenu search={search} setSearch={setSearch} />
      <div className=" h-[calc(100vh-157px)] bg-gray-50 overflow-y-scroll">
        <div className="flex flex-col justify-start gap-4 px-6 py-4 ">
          <ProjectCount count={filteredProjects.length} />

          <div className="flex flex-wrap">
            {filteredProjects.map((project) => (
              <ProjectContainer key={project.id} project={project} />
            ))}
          </div>
        </div>
      </div>
    </TimeEstimatesLayout>
  );
};

export default TimeEstimatesScreen;
