import React, { useCallback, useEffect, useState } from "react";
import ScheduleRouteSelector from "../Components/ScheduleRouteSelector";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";
import { Checkbox, Divider, Dropdown, Popover, Switch, Tooltip } from "antd";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { EditIcon } from "../../../Core/svgV2/EditIcon";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import UserQuickFilters from "../Components/UserQuickFilters";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { FilterIcon } from "../../../Core/svgV2/FilterIcon";

import Filters, { AppliedFilters } from "./Filters";
import { useNavigate, useParams } from "react-router-dom";
import {
  getGoalCount,
  getGoalsBySprint,
  getSprintsByPhase,
} from "../../../Core/redux/api/scheduleAPI";
import dayjs from "dayjs";
import CurrentActivePhase from "../Components/CurrentActivePhase";
import MyTaskFilterButton from "../Components/MyTaskFilterButton";
import usePermission from "../../../Core/hooks/usePermission";
import ExportIcon from "../../../Core/svgV2/ExportIcon";
import { convertArrayToCsv } from "../../../Core/utils/exportToCsv";
import { getPhaseName } from "../../../Core/utils/getPhaseName";
import getUsersName from "../../../Core/utils/getUserName";
import { useGetTenantMembers } from "../../../Core/redux/ReactQueryHooksV3/useTenantAPI";
import { useGetProjectDetails } from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import { PlusIcon, PlusIconSecondary } from "../../../Core/svgV2/PlusIcon";
import TaskIcon from "../../../Core/svgV3/TaskIcon";
import LabelIcon from "../../../Core/svgV3/LabelIcon";
import { ReleaseNotesIcon } from "../../../Core/svgV2/ReleaseNotesIcon";
import NewLabelModal from "../Modals/NewLabelModal";
import NewReleaseVersionModal from "../Modals/NewReleaseVersionModal";
import ManageLabelsModal from "../Modals/ManageLabelsModal";
import DeleteLabelModal from "../Modals/DeleteLabelModal";
import { DEFAULT_LIMIT } from "../../../Core/constants";
import { BugIcon } from "../../../Core/svgV2/BugIcon";
import AlertIcon from "../../../Core/svgV2/AlertIcon";
import EstimatesMissingModal from "../../TimeEstimates/Modals/EstimatesMissingModal";

const SprintMenu = () => {
  const navigate = useNavigate();
  const { projectId, sprintId } = useParams();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const { pendingTimeEstimates } = useAppSelector(
    (state) => state.timeEstimates
  );

  const {
    data: projectDetails,
    isLoading,
    isError,
  } = useGetProjectDetails(projectId);
  const { data: tenantMembersData, isLoading: isLoadingTenantMembers } =
    useGetTenantMembers();

  const tenantMembers = tenantMembersData?.data;
  const {
    sprints,
    filterGoalParameter,
    sprintKanbanView,
    goals,
    phases,
    showSubtaskView,
    manageLabelModal,
    deleteLabelModal,
    openBugAccordion,
  } = useAppSelector((state) => state.schedule);
  const [selectedSprint, setSelectedSprint] = useState(null);
  const [sprintIds, setSprintIds] = useState(
    filterGoalParameter?.sprintIds ? [...filterGoalParameter.sprintIds] : []
  );
  const dispatch = useAppDispatch();

  const [sprintOptions, setSprintOptions] = useState(false);
  const [showSprintOption, setShowSprintOption] = useState(false);
  const [timeEstimatesModal, setTimeEstimatesModal] = useState(false);

  useEffect(() => {
    dispatch(
      updateSchedule({
        key: "filterGoalParameter",
        value: {
          ...filterGoalParameter,
          sprintIds:
            sprintIds?.length === 0
              ? sprints.map((sprint) => sprint.id)
              : [...sprintIds],
        },
      })
    );
  }, [showSprintOption]);

  useEffect(() => {
    const activeSprint = sprints.find((s) => s.status === 2);
    const filteredSprints = sprints.filter((sprint) =>
      sprintIds.includes(sprint.id)
    );

    if (activeSprint) {
      setSelectedSprint(activeSprint);
      dispatch(
        updateSchedule({
          key: "filterGoalParameter",
          value: {
            ...filterGoalParameter,
            sprintIds:
              filteredSprints?.length > 0
                ? filteredSprints?.map((spr) => spr.id)
                : [activeSprint.id],
          },
        })
      );
    } else {
      setSelectedSprint(null);

      dispatch(
        updateSchedule({
          key: "filterGoalParameter",
          value: {
            ...filterGoalParameter,
            sprintIds:
              filteredSprints?.length > 0
                ? filteredSprints?.map((spr) => spr.id)
                : sprints.map((sprint) => sprint.id),
          },
        })
      );
    }
  }, [projectId, sprints]);

  const [newLabelModal, setNewLabelModal] = useState(false);
  const [newReleaseVersionModal, setNewReleaseVersionModal] = useState(false);

  const { hasPermission: PHASE_AND_SPRINT_EDIT } = usePermission("6");
  const { hasPermission: PHASE_AND_SPRINT_DELETE } = usePermission("7");
  const { hasPermission: TASK_EVENT_SCOPE_ADD } = usePermission("8");
  const activePhase = phases.find((p) => p.isOngoing);
  const selector = `[data-bugs="true"]`;
  const bugs = document.querySelector(selector) as HTMLElement;

  const getGroupItem = useCallback(() => {
    const groupItems = [
      {
        key: "allSprints",
        label: (
          <div className="w-full flex justify-between items-center px-2 py-[1px] ">
            <div
              className="flex py-[2px] gap-x-1 out-500-14 text-primary-600 hover:underline"
              role="button"
              onClick={async () => {
                {
                  await dispatch(
                    updateSchedule({
                      key: "editSprintModal",
                      value: {
                        visible: true,
                        mode: "new",
                        phaseId: activePhase?.projectPhaseId || "",
                      },
                    })
                  );
                }
              }}
            >
              <p>Create Sprint </p>
              <PlusIconSecondary className="text-primary-600" />
            </div>
            <div
              className="flex py-[2px] px-1.5 gap-x-0.5 text-primary-600 out-500-12 border border-gray-200 rounded-md hover:bg-primary-100 "
              onClick={() => {
                setShowSprintOption((prevValue) => !prevValue);
              }}
            >
              <p>
                {sprintIds.length === sprints.length
                  ? "All Sprints"
                  : sprintIds.length === 0
                  ? "All Sprints"
                  : sprintIds.length === 1
                  ? sprints.find((sprint) => sprint.id === sprintIds[0])
                      ?.status === 2
                    ? "Active Sprint"
                    : "1 Sprint"
                  : `${sprintIds.length} Sprint${
                      sprintIds.length === 1 ? "" : "s"
                    }`}
                {/* {selectedSprint?.status == 2 ? "Active Sprint" : "All Sprints"} */}
              </p>

              <ChevronIcon className="text-primary-600 " />
            </div>
          </div>
        ),
      },
      ...sprints.map((sprint, i) => {
        const isSelected = selectedSprint?.id == sprint.id;

        return {
          key: `${sprint.id}`,
          label: (
            <div className="w-full flex justify-between items-center px-2 py-[3px] gap-x-3">
              <div className="flex gap-x-3">
                <Checkbox
                  checked={sprintIds?.includes(sprint.id)}
                  onChange={(e) => {
                    setSprintIds((sprintIds) => {
                      if (sprintIds?.includes(sprint.id)) {
                        const filteredSprints = sprintIds.filter(
                          (sprintId) => sprintId !== sprint.id
                        );

                        return filteredSprints;
                      }
                      return [...sprintIds, sprint.id];
                    });
                  }}
                />
                <div className="flex flex-col out-500-14 max-w-[165px] ">
                  <p className="out-500-14 text-gray-700 truncate">
                    {sprint.name}
                  </p>
                  <p className="text-gray-500 out-400-10">
                    {dayjs(sprint.startDate).format("DD MMM YYYY")}-
                    {dayjs(sprint.endDate).format("DD MMM YYYY")}
                  </p>
                </div>
              </div>
              <CustomBadge
                text={
                  sprint.status === 2
                    ? "Active"
                    : sprint.status === 3
                    ? "Completed"
                    : "Upcoming"
                }
                color={
                  sprint.status === 2
                    ? "#165ABF"
                    : sprint.status === 3
                    ? "#027A48"
                    : "#667085"
                }
                className={`out-500-12 ${
                  sprint.status === 2
                    ? "bg-primary-100"
                    : sprint.status === 3
                    ? "bg-green-100"
                    : "bg-gray-200"
                }  `}
              />
              {/*                 
                <div className="out-300-14 leading-5 text-gray-700">
                  {sprint.name} {sprint.status == 2 && " (Active) "}{" "}
                  {sprint.status == 3 && " (Completed) "}
                  {isSelected &&
                    `(${
                      selectedSprint?.startDate
                        ? dayjs(selectedSprint?.startDate).format(
                            "MMMM DD YYYY"
                          )
                        : "N/A"
                    } - ${
                      selectedSprint?.endDate
                        ? dayjs(selectedSprint?.endDate).format("MMMM DD YYYY")
                        : "N/A"
                    })`}
                </div>
                {isSelected && <CheckIcon className="text-primary-600 ml-4" />} */}
            </div>
          ),
        };
      }),
    ];

    return groupItems;
  }, [sprints, sprintIds]);

  const sprintMoreOptions = [
    PHASE_AND_SPRINT_EDIT && {
      key: "1",
      label: (
        <div className="flex gap-x-2 items-center">
          <EditIcon className="text-gray-700" />
          <div className="out-300-14 leading-5 text-gray-700">Edit</div>
        </div>
      ),
    },
    PHASE_AND_SPRINT_DELETE && {
      key: "2",

      label: (
        <div className="flex group  gap-x-2 items-center">
          <DeleteIcon className="text-gray-700 group-hover:text-error-500" />
          <div className="out-300-14 leading-5 text-gray-700 group-hover:text-error-500">
            Delete
          </div>
        </div>
      ),
    },
  ];

  const onClick = ({ key }) => {
    switch (key) {
      case "1":
        dispatch(
          updateSchedule({
            key: "editSprintModal",
            value: {
              visible: true,
              sprint: selectedSprint,
              isEdit: true,
              mode: "edit",
            },
          })
        );
        break;
      case "2":
        dispatch(
          updateSchedule({
            key: "deleteSprintModal",
            value: { visible: true, sprintId: sprintId },
          })
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (sprintId !== selectedSprint?.id) {
      const currentSprint = sprints.find((s) => +s.id === +sprintId);
      if (currentSprint) {
        setSelectedSprint(currentSprint);
      }
    }
  }, [sprintId, sprints]);

  useEffect(() => {
    if (projectId) {
      dispatch(getSprintsByPhase({ projectId }));

      // if (selectedSprint && selectedSprint.projectId !== projectId) {
      //   setSelectedSprint(null);
      // }
    }
  }, [projectId]);

  const handleSearch = (e) => {
    const inputText = e.toLowerCase();

    dispatch(
      updateSchedule({
        key: "filterGoalParameter",
        value: {
          ...filterGoalParameter,
          searchValue: {
            searchValue: inputText,
            projectId: projectId,
            module: "sprints",
          },
        },
      })
    );
  };

  const formatToWeekAndYear = (startDate) => {
    const date = dayjs(startDate);
    const year = date.format("YY");
    const firstMonday = dayjs(`${date.year()}-01-01`).day(1);
    const weekNumber = Math.ceil(date.diff(firstMonday, "days") / 7) + 1;

    return `Week ${weekNumber} '${year}`;
  };

  return (
    <div className="py-3 px-6 border-b border-gray-200">
      <div className="flex justify-between">
        <div>
          <div className="flex gap-x-2">
            <div className="flex gap-x-2 items-center relative">
              <Dropdown
                dropdownRender={(origin) => {
                  return (
                    <div className="min-w-[320px] max-h-[50vh] overflow-auto shadow-xl rounded-lg">
                      {origin}
                    </div>
                  );
                }}
                open={sprintOptions}
                onOpenChange={(open) => {
                  if (open === false || sprintOptions === false) {
                    dispatch(
                      updateSchedule({
                        key: "filterGoalParameter",
                        value: {
                          ...filterGoalParameter,
                          sprintIds:
                            sprintIds?.length === 0
                              ? sprints.map((sprint) => sprint.id)
                              : [...sprintIds],
                        },
                      })
                    );
                  }
                  setSprintOptions(!sprintOptions);
                }}
                menu={{
                  items: getGroupItem(),
                }}
                trigger={["click"]}
              >
                <div className="flex gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-primary-500 bg-primary-50  rounded-[4px]">
                  <p className="out-500-14 text-primary-600">
                    {sprintId
                      ? selectedSprint?.name
                      : sprintIds.length === sprints.length
                      ? "All Sprints"
                      : sprintIds.length === 0
                      ? "All Sprints"
                      : `${sprintIds.length} Sprints`}
                  </p>
                  {selectedSprint?.status == 2 && sprintId && (
                    <CustomBadge
                      text="Active"
                      color="#F9FBFE"
                      className="out-500-12 bg-primary-500 "
                    />
                  )}
                  <button>
                    <div className="out-500-14 flex items-center gap-x-2 text-black">
                      <ChevronIcon className="text-primary-500" />
                    </div>
                  </button>
                </div>
              </Dropdown>
              {sprints &&
                sprintIds &&
                sprintIds.some((sprintId) => {
                  // Check if the corresponding sprint hasTimeEstimates is false
                  const sprint = sprints.find(
                    (sprint) => sprint.id === sprintId
                  );
                  return sprint && !sprint?.hasTimeEstimates; // Return true if hasTimeEstimates is false
                }) && (
                  <Tooltip
                    title={"Estimates are pending"}
                    placement="bottom"
                    style={{
                      display: "flex",
                      padding: "4px 8px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      borderRadius: "4px",
                      background: "#000000",
                      color: "#ffffff",
                    }}
                  >
                    <div
                      onClick={() => navigate(`/time-estimates/${projectId}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <AlertIcon height="14px" width="14px" />
                    </div>
                  </Tooltip>
                )}
              {showSprintOption && (
                <div className="flex flex-col min-w-[150px]  shadow-xl bg-white rounded-lg p-0.5 absolute top-[35px] left-[325px] ">
                  <div
                    className={`flex px-3 ${
                      sprintId
                        ? selectedSprint.status === 2
                          ? "text-gray-400"
                          : "text-gray-600 "
                        : "text-gray-600 "
                    } py-1.5 border-b hover:bg-gray-50 out-300-14 cursor-pointer`}
                    onClick={() => {
                      const filteredSprint = [...sprints]?.find(
                        (sprintId) => sprintId.isActive
                      ).id;

                      setSprintIds([filteredSprint]);
                      setShowSprintOption(false);
                      setSprintOptions(false);
                    }}
                  >
                    Active Sprint
                  </div>
                  <div
                    className={`flex ${
                      sprintId
                        ? "text-gray-600 "
                        : sprintIds.length === sprints.length ||
                          sprintIds.length === 0
                        ? "text-gray-400 "
                        : "text-gray-600 "
                    } px-3 py-1.5 out-300-14 hover:bg-gray-50 cursor-pointer`}
                    onClick={() => {
                      setSprintIds([]);
                      setShowSprintOption(false);
                      setSprintOptions(false);
                    }}
                  >
                    All Sprints
                  </div>
                </div>
              )}
            </div>
            {(PHASE_AND_SPRINT_DELETE || PHASE_AND_SPRINT_EDIT) &&
              selectedSprint?.status == 2 &&
              sprintId && (
                <Dropdown
                  dropdownRender={(origin) => {
                    return <div className="w-[200px]">{origin}</div>;
                  }}
                  menu={{ items: sprintMoreOptions, onClick }}
                  trigger={["click"]}
                >
                  <button>
                    <DotsVerticalIcon />
                  </button>
                </Dropdown>
              )}
          </div>

          <p className="out-300-14 text-gray-500 mt-0.5">
            Efficiently manage sprints and various issues like tasks, subtasks,
            events, and bugs to streamline project management.
          </p>
        </div>
        <div>
          <ScheduleRouteSelector current="sprints" />
        </div>
      </div>
      <div className="mt-4 flex items-center flex-wrap gap-y-2 justify-between">
        <div className="flex items-center">
          <CustomSearchBox
            placeholder="Search in Sprints"
            style={{
              width: "226px",
            }}
            search={
              filterGoalParameter?.searchValue?.projectId === projectId &&
              filterGoalParameter?.searchValue?.module === "sprints"
                ? filterGoalParameter?.searchValue?.searchValue
                : ""
            }
            setSearch={(e) => handleSearch(e)}
          />
          <AppliedFilters style={{ marginRight: "8px" }} />
          <Popover
            trigger={["click"]}
            content={
              <Filters
                onClose={() => {
                  setFiltersOpen(false);
                }}
              />
            }
            arrow={false}
            open={filtersOpen}
            placement="bottomLeft"
            onOpenChange={(val) => {
              console.log("yo Popover open state changed:", val);
              setFiltersOpen(val);
            }}
          >
            <button
              className={`hover:bg-gray-50 h-[30px] w-[30px] flex justify-center items-center ${
                filtersOpen && "bg-gray-50"
              }`}
            >
              <FilterIcon className="text-gray-500" />
            </button>
          </Popover>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-x-2">
            {sprintKanbanView && sprintId && (
              <div className="flex gap-x-1.5">
                <p>Show Subtasks</p>
                <Switch
                  checked={showSubtaskView}
                  size={"small"}
                  onChange={() =>
                    dispatch(
                      updateSchedule({
                        key: "showSubtaskView",
                        value: !showSubtaskView,
                      })
                    )
                  }
                  className={`${
                    showSubtaskView ? "afterSwitch" : "beforeSwitch"
                  }`}
                />
              </div>
            )}
          </div>

          {selectedSprint?.status == 2 &&
          sprintKanbanView &&
          showSubtaskView ? (
            <CustomButton
              width="74px"
              className={`ml-3 bg-transparent out-500-14 ${"text-error-600"} `}
              text="Bugs"
              // icon={
              //   <ChevronIcon className=" ml-[-8px] text-gray-700 -rotate-90" />
              // }
              // iconAlignment="right"
              onClick={async () => {
                if (bugs) {
                  await dispatch(
                    updateSchedule({
                      key: "openBugAccordion",
                      value: true,
                    })
                  );
                  bugs.scrollIntoView({
                    block: "start",
                    inline: "nearest",
                    behavior: "smooth",
                  });
                }
              }}
            />
          ) : (
            <MyTaskFilterButton />
          )}

          <Divider type="vertical" className="h-[30px]" />
          <UserQuickFilters />

          {sprintId ? (
            <>
              <CustomButton
                className="bg-transparent ml-4  border out-500-14 text-gray-700 border-gray-200"
                text={sprintKanbanView ? "List View" : "Kanban View"}
                height="30px"
                onClick={() => {
                  dispatch(
                    getGoalsBySprint({
                      projectId,
                      sprintId,
                    })
                  );
                  dispatch(
                    updateSchedule({
                      key: "sprintKanbanView",
                      value: !sprintKanbanView,
                    })
                  );
                }}
              />
              {selectedSprint?.status === 3 ? (
                <CustomButton
                  className="bg-transparent mx-3 border out-500-14 text-gray-700 border-gray-200 "
                  icon={<CheckIcon className="text-gray-700 ml-2.5" />}
                  text={`Completed on ${dayjs(selectedSprint.endDate).format(
                    "MMMM DD YYYY"
                  )}`}
                  height="30px"
                />
              ) : selectedSprint?.status === 2 ? (
                <>
                  {PHASE_AND_SPRINT_EDIT && selectedSprint && (
                    <CustomButton
                      className="bg-transparent mx-3 border out-500-14 text-gray-700 border-gray-200 "
                      text="Complete Sprint"
                      height="30px"
                      onClick={async () => {
                        await dispatch(getGoalCount({ projectId, sprintId }));
                        dispatch(
                          updateSchedule({
                            key: "completeSprintModal",
                            value: {
                              visible: true,
                              sprintId: selectedSprint.id,
                              sprint: selectedSprint,
                            },
                          })
                        );
                      }}
                    />
                  )}
                  {TASK_EVENT_SCOPE_ADD && sprintId && +sprintId > 0 && (
                    <Popover
                      trigger={["click"]}
                      content={
                        <div className="flex flex-col py-0.5 min-w-[216px] bg-white ">
                          <div
                            className="flex px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                              navigate(
                                `/schedule/${projectId}/task/new?sprintId=${sprintId}`
                              );
                              dispatch(
                                updateSchedule({
                                  key: "newTask",
                                  value: null,
                                })
                              );
                              dispatch(
                                updateSchedule({
                                  key: "isBug",
                                  value: false,
                                })
                              );
                            }}
                          >
                            <TaskIcon className="text-gray-500" />
                            <p className="out-300-14 ">Task</p>
                          </div>
                          <div
                            className="flex px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                              navigate(
                                `/schedule/${projectId}/task/new?sprintId=${sprintId}`
                              );
                              dispatch(
                                updateSchedule({
                                  key: "isBug",
                                  value: true,
                                })
                              );
                            }}
                          >
                            <BugIcon className="text-gray-500" />
                            <p className="out-300-14 ">Bug</p>
                          </div>
                          <div
                            className="flex   px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                            onClick={() => setNewLabelModal(true)}
                          >
                            <LabelIcon className="text-gray-500" />
                            <p className="out-300-14 ">Label</p>
                          </div>
                          <div className="w-[200px] h-[1px] bg-gray-200 mle-2"></div>
                          <div
                            className="flex px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                            onClick={async () => {
                              {
                                await dispatch(
                                  updateSchedule({
                                    key: "editSprintModal",
                                    value: {
                                      visible: true,
                                      mode: "new",
                                      phaseId:
                                        activePhase?.projectPhaseId || "",
                                    },
                                  })
                                );
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M1.85925 5.54589C1.85925 5.54589 2.91239 4.16209 3.76797 3.33642C4.62355 2.51074 5.80587 2 7.11189 2C9.72274 2 11.8393 4.04114 11.8393 6.559C11.8393 9.07687 9.72274 11.118 7.11189 11.118H1.33398H14.6673M1.85925 5.54589V2.50656M1.85925 5.54589H5.01083M14.6673 11.118L12.5074 13.3333M14.6673 11.118L12.5074 9.03506"
                                stroke="#475467"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <p className="out-300-14 ">Sprint</p>
                          </div>
                          <div
                            className="flex px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                            onClick={() => setNewReleaseVersionModal(true)}
                          >
                            <ReleaseNotesIcon className="text-gray-500" />
                            <p className="out-300-14 ">Release Version</p>
                          </div>
                        </div>
                      }
                      arrow={false}
                      placement="bottomLeft"
                      onOpenChange={(val) => setCreateModal(val)}
                    >
                      <CustomButton
                        text="Create"
                        className="pl-2.5 pr-[5px] bg-primary-600 text-white out-500-14"
                        height="30px"
                        icon={<ChevronIcon className="text-white" />}
                        iconAlignment="right"
                      />
                    </Popover>
                  )}
                </>
              ) : (
                <>
                  {PHASE_AND_SPRINT_EDIT && selectedSprint && (
                    <CustomButton
                      className="bg-transparent mx-3 border out-500-14 text-gray-700 border-gray-200 "
                      text="Start Sprint"
                      height="30px"
                      onClick={() => {
                        // Check if time estimates are missing for the selected sprint
                        if (!selectedSprint.hasTimeEstimates) {
                          // If missing, show the modal
                          setTimeEstimatesModal(true);
                        } else {
                          // If not missing, proceed with starting the sprint
                          dispatch(
                            updateSchedule({
                              key: "editSprintModal",
                              value: {
                                visible: true,
                                sprint: selectedSprint,
                                mode: "start",
                              },
                            })
                          );
                        }
                      }}
                    />
                  )}
                  {TASK_EVENT_SCOPE_ADD && sprintId && +sprintId > 0 && (
                    <Popover
                      trigger={["click"]}
                      content={
                        <div className="flex flex-col py-0.5 min-w-[216px]">
                          <div
                            className="flex px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                              dispatch(
                                updateSchedule({
                                  key: "newTask",
                                  value: null,
                                })
                              );
                              navigate(
                                `/schedule/${projectId}/task/new?sprintId=${sprintId}`
                              );
                            }}
                          >
                            <TaskIcon className="text-gray-500" />
                            <p className="out-300-14 ">Task</p>
                          </div>
                          <div
                            className="flex   px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                            onClick={() => setNewLabelModal(true)}
                          >
                            <LabelIcon className="text-gray-500" />
                            <p className="out-300-14 ">Label</p>
                          </div>
                          <div className="w-[200px] h-[1px] bg-gray-200 mle-2"></div>
                          <div
                            className="flex px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                            onClick={async () => {
                              {
                                await dispatch(
                                  updateSchedule({
                                    key: "editSprintModal",
                                    value: {
                                      visible: true,
                                      mode: "new",
                                      phaseId:
                                        activePhase?.projectPhaseId || "",
                                    },
                                  })
                                );
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M1.85925 5.54589C1.85925 5.54589 2.91239 4.16209 3.76797 3.33642C4.62355 2.51074 5.80587 2 7.11189 2C9.72274 2 11.8393 4.04114 11.8393 6.559C11.8393 9.07687 9.72274 11.118 7.11189 11.118H1.33398H14.6673M1.85925 5.54589V2.50656M1.85925 5.54589H5.01083M14.6673 11.118L12.5074 13.3333M14.6673 11.118L12.5074 9.03506"
                                stroke="#475467"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <p className="out-300-14 ">Sprint</p>
                          </div>
                          <div
                            className="flex px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                            onClick={() => setNewReleaseVersionModal(true)}
                          >
                            <ReleaseNotesIcon className="text-gray-500" />
                            <p className="out-300-14 ">Release Version</p>
                          </div>
                        </div>
                      }
                      arrow={false}
                      placement="bottomLeft"
                      onOpenChange={(val) => setCreateModal(val)}
                    >
                      <CustomButton
                        text="Create"
                        className="pl-2.5 pr-[5px] bg-primary-600 text-white out-500-14"
                        height="30px"
                        icon={<ChevronIcon className="text-white" />}
                        iconAlignment="right"
                      />
                    </Popover>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {TASK_EVENT_SCOPE_ADD && (
                <Popover
                  trigger={["click"]}
                  content={
                    <div className="flex flex-col py-0.5 min-w-[216px]">
                      <div
                        className="flex px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                        onClick={() => {
                          dispatch(
                            updateSchedule({
                              key: "newTask",
                              value: null,
                            })
                          );
                          navigate(`/schedule/${projectId}/task/new`);
                        }}
                      >
                        <TaskIcon className="text-gray-500" />
                        <p className="out-300-14 ">Task</p>
                      </div>
                      <div
                        className="flex   px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                        onClick={() => setNewLabelModal(true)}
                      >
                        <LabelIcon className="text-gray-500" />
                        <p className="out-300-14 ">Label</p>
                      </div>
                      <div className="w-[200px] h-[1px] bg-gray-200 mle-2"></div>
                      <div
                        className="flex px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                        onClick={async () => {
                          {
                            await dispatch(
                              updateSchedule({
                                key: "editSprintModal",
                                value: {
                                  visible: true,
                                  mode: "new",
                                  phaseId: activePhase?.projectPhaseId || "",
                                },
                              })
                            );
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M1.85925 5.54589C1.85925 5.54589 2.91239 4.16209 3.76797 3.33642C4.62355 2.51074 5.80587 2 7.11189 2C9.72274 2 11.8393 4.04114 11.8393 6.559C11.8393 9.07687 9.72274 11.118 7.11189 11.118H1.33398H14.6673M1.85925 5.54589V2.50656M1.85925 5.54589H5.01083M14.6673 11.118L12.5074 13.3333M14.6673 11.118L12.5074 9.03506"
                            stroke="#475467"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p className="out-300-14 ">Sprint</p>
                      </div>
                      <div
                        className="flex px-[14px] py-2.5 gap-x-2 w-full cursor-pointer hover:bg-gray-100"
                        onClick={() => setNewReleaseVersionModal(true)}
                      >
                        <ReleaseNotesIcon className="text-gray-500" />
                        <p className="out-300-14 ">Release Version</p>
                      </div>
                    </div>
                  }
                  arrow={false}
                  placement="bottomLeft"
                  onOpenChange={(val) => setCreateModal(val)}
                >
                  <CustomButton
                    text="Create"
                    className="pl-2.5 ml-3 pr-[5px] bg-primary-600 text-white out-500-14"
                    height="30px"
                    icon={<ChevronIcon className="text-white" />}
                    iconAlignment="right"
                  />
                </Popover>
              )}
            </>
          )}
        </div>
      </div>
      <NewLabelModal
        visible={newLabelModal}
        onClose={() => setNewLabelModal(false)}
      />
      <NewReleaseVersionModal
        visible={newReleaseVersionModal}
        onClose={() => setNewReleaseVersionModal(false)}
      />

      {manageLabelModal && (
        <ManageLabelsModal
          visible={manageLabelModal}
          onClose={() => dispatch(resetSchedule(["manageLabelModal"]))}
        />
      )}
      <DeleteLabelModal
        visible={deleteLabelModal.visible}
        onClose={() => dispatch(resetSchedule(["deleteLabelModal"]))}
      />
      <EstimatesMissingModal
        onClose={() => setTimeEstimatesModal(false)}
        title="Time estimates are missing!"
        description={`You missed adding the time estimates for <span style="font-weight: 500;">${formatToWeekAndYear(
          selectedSprint?.startDate
        )}</span>. Please add the estimates before the sprint begins.`}
        buttonOneText="I’ll Do It Later"
        buttonTwoText="Add Time Estimates"
        onButtonOneClick={() => {
          // Close modal and proceed with starting the sprint even though estimates are missing
          setTimeEstimatesModal(false);
          dispatch(
            updateSchedule({
              key: "editSprintModal",
              value: {
                visible: true,
                sprint: selectedSprint,
                mode: "start",
              },
            })
          );
        }}
        onButtonTwoClick={async () => {
          // Navigate to the time estimates page for the current project
          navigate(`/time-estimates/${projectId}`);
          setTimeEstimatesModal(false); // Optionally close the modal
        }}
        visible={timeEstimatesModal}
      />
    </div>
  );
};

export default SprintMenu;
