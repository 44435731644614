import React, { useEffect, useState } from "react";
import { Dropdown, Divider, Popover, Tooltip } from "antd";
import { ChevronIcon } from "../../Core/svgV2/Chevron"; // Ensure this is correctly imported
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import CustomBadge from "../../Core/CommonV2/CustomBadge";
import { HideIcon } from "../../Core/svgV3/HideIcon";
import { ReportsArrowIcon } from "../../Core/svgV3/ReportsArrowIcon";
import { useGetTenantInsights } from "../../Core/redux/ReactQueryHooksV3/useReportsAPI";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import dayjs from "dayjs";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";

const DataField = ({
  label,
  value,
  previousValue,
  showArrow,
  isTime = false,
}) => {
  const formatTime = (timeInHours) => {
    // Get the integer part for hours
    const hours = Math.floor(timeInHours) || 0;

    // Get the fractional part, convert it to minutes
    const minutes = Math.round((timeInHours - hours) * 60) || 0;

    // Build the formatted time string
    const formattedTime = `${hours}h ${minutes > 0 ? minutes + "m" : ""}`;

    return formattedTime;
  };
  const getIconRotationAndColor = (currentValue, previousValue) => {
    console.log(previousValue, "rotation", currentValue);
    if (currentValue > previousValue) {
      console.log("rotation inside if");
      return {
        rotation: "rotate-180", // Arrow pointing down for increase
        color: "text-error-700", // Red for increase
      };
    } else if (currentValue < previousValue) {
      console.log("rotation inside else if");
      return {
        rotation: "", // No rotation for decrease
        color: "text-green-700", // Green for decrease
      };
    }
    console.log("rotation out side both");

    return { rotation: "hidden", color: "" }; // Default state
  };

  const { rotation, color } = showArrow
    ? getIconRotationAndColor(value, previousValue)
    : { rotation: "", color: "" };

  console.log(rotation, "rotation", color);

  return (
    <div className="flex justify-between">
      <div className="out-300-14 text-black leading-[20px]">{label}:</div>
      <div className="flex items-center">
        {showArrow && (
          <div className={`${rotation}`}>
            <ReportsArrowIcon className={`${color} w-4 h-4 mr-1`} />
          </div>
        )}
        <div className="out-500-14 text-black leading-[20px]">
          {isTime ? formatTime(value) : value}
        </div>
      </div>
    </div>
  );
};

const PriorityBar = ({ data }) => {
  if (!data?.priorityDistribution || data?.totalBugs === 0) return null;

  const popoverContent = (color, priorityType, value) => (
    <div className="shadow-xl rounded-lg bg-white p-2 text-center text-black out-300-14">
      <span
        style={{
          display: "inline-block",
          width: "10px",
          height: "10px",
          backgroundColor: color,
          borderRadius: "50%",
          marginRight: "5px",
        }}
      />
      {priorityType} priority:{" "}
      <span className="out-500-14 text-black ml-0.5">{value}h</span>
    </div>
  );

  return (
    <div className="w-full bg-gray-200 rounded h-2 mt-1 relative overflow-hidden">
      {/* Low Priority */}
      <Popover
        content={popoverContent(
          "#D1D5DB",
          "Low",
          data?.priorityDistribution?.low
        )}
        trigger="hover"
        placement="bottom"
      >
        <div
          className="absolute top-0 left-0 h-2 rounded-l-full bg-gray-200"
          style={{
            width: `${
              (data?.priorityDistribution?.low / data?.totalBugs) * 100
            }%`,
          }}
        ></div>
      </Popover>

      {/* Medium Priority */}
      <Popover
        content={popoverContent(
          "#3B82F6",
          "Med",
          data.priorityDistribution.medium
        )}
        trigger="hover"
        placement="bottom"
      >
        <div
          className="absolute top-0 left-0 h-2 bg-primary-500"
          style={{
            width: `${
              (data?.priorityDistribution?.medium / data?.totalBugs) * 100
            }%`,
            marginLeft: `${
              (data?.priorityDistribution?.low / data?.totalBugs) * 100
            }%`,
          }}
        ></div>
      </Popover>

      {/* High Priority */}
      <Popover
        content={popoverContent(
          "#EF4444",
          "High",
          data.priorityDistribution.high
        )}
        trigger="hover"
        placement="bottom"
      >
        <div
          className="absolute top-0 left-0 h-2 rounded-r-full bg-error-700"
          style={{
            width: `${
              (data?.priorityDistribution.high / data.totalBugs) * 100
            }%`,
            marginLeft: `${
              (data?.priorityDistribution?.low / data?.totalBugs) * 100 +
              (data?.priorityDistribution?.medium / data?.totalBugs) * 100
            }%`,
          }}
        ></div>
      </Popover>
    </div>
  );
};

const CompareTrendsScreen = ({ column1 = {}, column2 = {}, column3 = {} }) => {
  // Assuming you're fetching this from an API or state
  const { compareTrendsWeeks, selectAssignees, trendsCategory } =
    useAppSelector((state) => state.dashboard);
  const { data } = useGetTenantInsights(); // Adjust based on your actual data-fetching logic
  const dispatch = useAppDispatch();

  const getCurrentWeekNumber = () => {
    const startOfYear = dayjs().startOf("year");
    const currentDate = dayjs();
    const dayOfYear = currentDate.diff(startOfYear, "day") + 1; // Get the day of the year
    return Math.ceil(dayOfYear / 7) - 1; // Calculate the week number
  };

  // Dynamically map weeks data from API response
  const filteredWeeks =
    data?.filteredWeeks?.map((weekData) => {
      const { startDate, endDate, weekName } = weekData;

      const start = new Date(startDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
      });
      const end = new Date(endDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      return {
        week: weekName,
        dates: `${start} - ${end}`,
      };
    }) || [];

  const [selectedWeeks, setSelectedWeeks] = useState(compareTrendsWeeks);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [searchTerms, setSearchTerms] = useState(["", "", ""]);
  const [visibleAssignees, setVisibleAssignees] = useState([]);

  useEffect(() => {
    const uniqueAssignees = new Set([
      ...Object.keys(column1 || {}),
      ...Object.keys(column2 || {}),
      ...Object.keys(column3 || {}),
    ]);
    setVisibleAssignees(Array.from(uniqueAssignees));
  }, [column1, column2, column3]);

  useEffect(() => {
    // Update Redux state when selectedWeeks change
    dispatch(
      updateDashboard({ key: "compareTrendsWeeks", value: selectedWeeks })
    );
  }, [selectedWeeks]);

  const handleWeekChange = (weekIndex, newWeek) => {
    const updatedWeeks = [...selectedWeeks];
    updatedWeeks[weekIndex] = newWeek;
    setSelectedWeeks(updatedWeeks);

    setOpenDropdownIndex(null); // Close the dropdown after selection
  };

  const handleSearchChange = (weekIndex, value) => {
    const updatedSearchTerms = [...searchTerms];
    updatedSearchTerms[weekIndex] = value;
    setSearchTerms(updatedSearchTerms);
  };

  // Filter weeks based on search term
  const getFilteredWeeks = (weekIndex) => {
    const search = searchTerms[weekIndex].toLowerCase();
    return filteredWeeks.filter(
      (weekData) =>
        weekData.week.toLowerCase().includes(search) ||
        weekData.dates.toLowerCase().includes(search)
    );
  };

  const handleAssigneeVisibility = (assigneeId) => {
    console.log(assigneeId, "right here sele Asig");
    dispatch(
      updateDashboard({
        key: "selectAssignees",
        value: selectAssignees?.filter((id) => id !== +assigneeId), // Remove the hidden assigneeId
      })
    );
  };

  console.log(column1, "col 2", column2, "col 3", column3);

  const getAssigneeData = (assigneeName) => {
    const columns = [column1, column2, column3];

    for (const column of columns) {
      if (column[assigneeName]) {
        return column[assigneeName];
      }
    }

    return null; // Return null if no data is found
  };

  return (
    <div>
      {/* Table header */}
      <div className="flex sticky top-0 z-10">
        <div className="basis-[15.625%] p-3 border border-gray-200 bg-gray-50 flex items-center">
          <p className="out-500-16">Assignees</p>
        </div>
        {selectedWeeks?.map((week, index) => (
          <div
            key={index}
            className="basis-[28.125%] flex justify-center text-center py-2.5 border border-gray-200 bg-gray-25"
          >
            <Dropdown
              overlay={
                <div
                  className="min-w-[300px] max-h-[50vh] overflow-auto shadow-xl rounded-lg bg-white"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="px-4 py-2">
                    {/* Custom Search Box */}
                    <CustomSearchBox
                      search={searchTerms[index]}
                      setSearch={(value) => handleSearchChange(index, value)}
                      placeholder="Search weeks or dates"
                      className=""
                    />
                  </div>
                  <div className="out-500-14 leading-[20px] px-4 py-2 text-[#292927]">
                    Select a week to compare
                  </div>
                  {/* Week Options */}
                  <div>
                    {getFilteredWeeks(index).map((weekData) => (
                      <div
                        key={weekData.week}
                        className={`flex justify-between items-center border-b px-4 py-2 cursor-pointer ${
                          selectedWeeks[index] === weekData.week
                            ? "bg-primary-50 border-primary-100"
                            : "hover:bg-gray-100"
                        }`}
                        onClick={() => handleWeekChange(index, weekData.week)}
                      >
                        <div>
                          <div className="font-medium">{weekData.week}</div>
                          <div className="text-gray-500 text-xs">
                            {weekData.dates}
                          </div>
                        </div>
                        {weekData.week === `Week ${getCurrentWeekNumber()}` && (
                          <div>
                            <CustomBadge
                              color={"#165ABF"}
                              text={"Current"}
                              className="bg-primary-100"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              }
              trigger={["click"]}
              open={openDropdownIndex === index}
              onOpenChange={(open) => {
                if (open) {
                  setOpenDropdownIndex(index); // Open the clicked dropdown
                } else {
                  setOpenDropdownIndex(null); // Close if clicked again
                }
              }}
              placement="bottom"
            >
              <div
                className={`flex gap-x-1.5 items-center pl-[14px] pr-[10px] py-1 rounded-[4px] cursor-pointer ${
                  openDropdownIndex === index
                    ? index === 0
                      ? "bg-primary-100 border border-primary-200"
                      : "bg-gray-100 border border-gray-200"
                    : "border border-white"
                }`}
              >
                <p
                  className={`out-500-14 ${
                    0 === index ? "text-primary-700" : "text-[#344054]"
                  }`}
                >
                  {selectedWeeks[index] ? selectedWeeks[index] : "Select Week"}
                </p>
                {selectedWeeks[index] && (
                  <p
                    className={`out-300-12 ${
                      0 === index ? "text-primary-700" : "text-[#344054]"
                    }`}
                  >
                    (
                    {
                      filteredWeeks?.find(
                        (entry) => entry?.week === selectedWeeks[index]
                      )?.dates
                    }
                    )
                  </p>
                )}
                <ChevronIcon
                  className={`${
                    openDropdownIndex === index ? "rotate-180" : ""
                  } ${0 === index ? "text-primary-700" : "text-[#344054]"}`}
                />
              </div>
            </Dropdown>
          </div>
        ))}
      </div>

      {/* Assignee data rows */}
      <div>
        {visibleAssignees?.map((assigneeName, index) => {
          const assignee = getAssigneeData(assigneeName);
          return (
            <div key={index} className="flex group">
              {/* Assignee column */}
              <div className="basis-[15.625%] flex items-center justify-between out-500-16 px-3 border border-gray-200 bg-gray-50">
                <div className="flex gap-2">
                  {/* <img
                    src={assignee?.avatar}
                    alt={`${assigneeName}'s avatar`}
                    className="w-6 h-6 rounded-lg mr-2"
                  /> */}
                  <CustomAvatar
                    title={assigneeName}
                    size={24}
                    shape="square"
                    src={assignee?.avatar}
                  />
                  <Tooltip title={assigneeName}>
                    <span className="text-black truncate">{assigneeName}</span>
                  </Tooltip>
                </div>
                <div
                  className="opacity-0 group-hover:opacity-100 cursor-pointer"
                  onClick={() => handleAssigneeVisibility(assignee?.assigneeId)}
                >
                  <HideIcon className="text-gray-700" />
                </div>
              </div>

              {/* Week data columns */}
              {selectedWeeks?.map((week, weekIndex) => {
                let data;
                if (weekIndex === 0) {
                  data = column1[assigneeName]?.data; // For the first column
                } else if (weekIndex === 1) {
                  data = column2[assigneeName]?.data; // For the second column
                } else if (weekIndex === 2) {
                  data = column3[assigneeName]?.data; // For the third column
                }
                const previousData =
                  weekIndex > 0
                    ? weekIndex === 1
                      ? column1[assigneeName]?.data // Access only the 'data' part for column 2
                      : column2[assigneeName]?.data // Access only the 'data' part for column 3
                    : null;

                console.log(data, "helo previous data ", previousData);
                return (
                  <div
                    key={weekIndex}
                    className="basis-[28.125%] flex flex-col justify-center text-center border border-gray-200 bg-[#fcfdff] group-hover:bg-grey-50"
                  >
                    <div className="p-2">
                      {/* Render Time estimated if selected */}
                      {trendsCategory?.includes("Time estimate") && (
                        <DataField
                          label="Time estimated"
                          value={data?.timeEstimated}
                          previousValue={
                            weekIndex === 0 ? null : previousData?.timeEstimated
                          }
                          showArrow={weekIndex !== 0}
                          isTime={true}
                        />
                      )}
                      {/* Render Time spent if selected */}
                      {trendsCategory?.includes("Time spent") && (
                        <DataField
                          label="Time spent"
                          value={data?.timeSpent}
                          previousValue={
                            weekIndex === 0 ? null : previousData?.timeSpent
                          }
                          showArrow={weekIndex !== 0}
                          isTime={true}
                        />
                      )}
                    </div>
                    {(data?.totalBugs > 0 || data?.timeOnBugs > 0) && (
                      <Divider className="my-0" />
                    )}
                    <div className="p-2">
                      {/* Render Time on bugs if selected */}
                      {trendsCategory?.includes("Time on bugs") &&
                        data?.timeOnBugs > 0 && (
                          <DataField
                            label="Total time on bugs"
                            value={data?.timeOnBugs}
                            previousValue={
                              weekIndex === 0 ? null : previousData?.timeOnBugs
                            }
                            showArrow={weekIndex !== 0}
                            isTime={true}
                          />
                        )}
                      {/* Render Number of bugs if selected */}
                      {trendsCategory?.includes("Number of bugs") &&
                        data?.totalBugs > 0 && (
                          <DataField
                            label="Total no. of bugs"
                            value={data?.totalBugs}
                            previousValue={null}
                            showArrow={false}
                            isTime={false}
                          />
                        )}

                      {/* Priority Bar */}
                      {trendsCategory?.includes("Time on bugs") &&
                        trendsCategory?.includes("Number of bugs") && (
                          <PriorityBar data={data} />
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CompareTrendsScreen;
