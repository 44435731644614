import React, { useEffect, useMemo, useRef, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { Tooltip, Divider, Popover } from "antd";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { useTooltip } from "@nivo/tooltip";
import { DeleteScheduleIcon } from "../../Core/svgV3/DeleteIcon";
import { reportColorsScheme } from "../../Core/constants";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  calculateNiceTickInterval,
  CustomGridLine,
  generateDynamicTickValues,
  roundToNextMaxValue,
} from "./CustomBulletChart";
import { InfoIcon, ReportsInfoIcon } from "../../Core/svgV3/InfoIcon";

type CustomBarComponentProps = {
  bar: {
    x: number;
    y: number;
    width: number;
    height: number;
    color: string;
    index: number;
    key: string;
    data: any;
  };
  isTopMost: boolean;
};

const formatTime = (timeInHours) => {
  if (timeInHours === 0) return "0h 0m";
  const hours = Math.floor(timeInHours);
  const minutes = Math.round((timeInHours - hours) * 60);
  return `${hours}h ${minutes}m`;
};

const DividedBarChart = ({
  type = 2,
  data,
  keys,
  indexByX,
  indexByY = "Value",
  colorsScheme = reportColorsScheme,
  heading = "Team time on projects",
  tooltip = "",
  showTotalValues = true,
  weeks = 1,
  heightKey = 21,
  legendTextX = indexByX,
}) => {
  const dispatch = useAppDispatch();
  const { selectedCharts } = useAppSelector((state) => state.dashboard);
  const [currentPage, setCurrentPage] = useState(0);
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const scrollTolerance = 1;
  const [containerMaxWidth, setContainerMaxWidth] = useState("100%");
  const [isDotsPopoverOpen, setIsDotsPopoverOpen] = useState(false);

  console.log(heightKey, "heightKey");
  useEffect(() => {
    const updateMaxWidth = () => {
      const availableSpace = window.innerWidth - 400; // Adjust based on your layout
      const containerWidth =
        availableSpace > 800 ? availableSpace / 2 : availableSpace; // Divide by 2 if space > 800px

      setContainerMaxWidth(`${containerWidth}px`);
    };

    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (container) {
        const isAtLeftEdge = container.scrollLeft <= scrollTolerance;
        const isAtRightEdge =
          container.scrollWidth - container.scrollLeft <=
          container.clientWidth + scrollTolerance;

        setShowLeftArrow(!isAtLeftEdge);
        setShowRightArrow(!isAtRightEdge);
      }
    };

    const container = scrollContainerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
    }

    updateMaxWidth(); // Initial width calculation
    window.addEventListener("resize", updateMaxWidth);

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", updateMaxWidth);
    };
  }, [keys, data]);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -100 : 100;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const lastObject = data[data.length - 1]; // Get the last object
  const max = Math.max(lastObject?.max, 20); // Use max from the last object or fallback to provided maxValue
  const maxValue = max
    ? roundToNextMaxValue(max, calculateNiceTickInterval(max))
    : 20;

  const tickValues = generateDynamicTickValues(maxValue);
  // console.log(maxValue, "max", max);

  // Filter out the last object from the data array
  const filteredData = data.slice(0, -1);
  // console.log(filteredData, "filteredData for div", data);

  // Find the top-most project for each bar group
  const topMostNames = useMemo(() => {
    const names: { [key: string]: string } = {};

    filteredData.forEach((item) => {
      for (let i = keys.length - 1; i >= 0; i--) {
        const key = keys[i];
        if (item[key] !== undefined && item[key] !== 0) {
          names[item[indexByX]?.trim()] = key?.trim(); // Store the project name
          break;
        }
      }
    });

    return names;
  }, [filteredData, keys, indexByX]);

  const colorMapping = useMemo(() => {
    const colorScale = colorsScheme;
    return keys.reduce((acc, key, i) => {
      // console.log("acc:", acc, "key:", key, "i:", i);
      acc[key] = colorScale[i % colorScale.length];
      return acc;
    }, {});
  }, [keys, colorsScheme]);

  // Helper function to calculate luminance of a hex color
  const getLuminance = (hexColor: string) => {
    const rgb = parseInt(hexColor.slice(1), 16); // Convert hex to RGB
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    // Use the luminance formula
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  // Main component
  const CustomBarComponent: React.FC<CustomBarComponentProps> = ({
    bar,
    isTopMost,
  }) => {
    const { x, y, width, height, color, data } = bar;
    // console.log(bar, "here is div", isTopMost);

    // Adjusting the width and x positioning
    const adjustedWidth = 20;
    const adjustedX = x + (width - adjustedWidth) / 2;

    // Define a path to round only the top corners
    const cornerRadius = Math.min(6, height / 2);

    // Define a path to round only the top corners
    const roundedPath = `
      M${adjustedX},${y + height}
      L${adjustedX},${y + cornerRadius}
      Q${adjustedX},${y} ${adjustedX + cornerRadius},${y}
      L${adjustedX + adjustedWidth - cornerRadius},${y}
      Q${adjustedX + adjustedWidth},${y} ${adjustedX + adjustedWidth},${
      y + cornerRadius
    }
      L${adjustedX + adjustedWidth},${y + height}
      Z
    `;

    // Tooltip hook from Nivo
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const handleMouseEnter = (event: React.MouseEvent<SVGElement>) => {
      showTooltipFromEvent(
        <div className="shadow-xl rounded-lg bg-white p-2">
          <span
            style={{
              display: "inline-block",
              backgroundColor: color,
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          />
          {data.id}:
          <strong className="ml-0.5">
            {indexByY === "Hours"
              ? formatTime(data?.value || 0)
              : data?.value || 0}
          </strong>
        </div>,
        event
      );
    };

    const handleMouseLeave = () => {
      hideTooltip();
    };

    // Determine if text should be black or white based on luminance
    const textColor = useMemo(() => {
      const luminance = getLuminance(color);
      return luminance > 128 ? "#000000" : "#FFFFFF"; // Use black if bright, white if dark
    }, [color]);

    // Set the font size for the number inside the bar
    const fontSize = 10;
    const value = Math.round(data?.value || 0);
    const digitCount = value.toString().length;

    // Set a required height based on the number of digits
    const requiredHeightForText = digitCount * fontSize; // Adjust as necessary
    const shouldRenderText = height > requiredHeightForText;

    const totalTime = Math.round(data?.data?.totalTime);
    const totalTimeDigitCount = totalTime.toString().length;

    // Set the y-offset based on the digit count
    const totalTimeYOffset = totalTimeDigitCount;

    return (
      <g>
        {isTopMost ? (
          <>
            <path
              d={roundedPath}
              fill={color}
              onMouseEnter={handleMouseEnter}
              onMouseMove={handleMouseEnter} // Ensure tooltip follows mouse movement
              onMouseLeave={handleMouseLeave}
            />

            {/* Display totalTime above the bar when isTopMost */}
            {data?.data?.totalTime && (
              <text
                x={adjustedX + adjustedWidth / 2 + 5} // Centered horizontally
                y={y - 6 - totalTimeYOffset} // Adjust the y-position to place it just above the bar, using the offset
                fill={"#000000"}
                fontSize="12"
                textAnchor="middle"
                className="cursor-default"
                transform={`rotate(-90, ${adjustedX + adjustedWidth / 2}, ${
                  y - 10 - totalTimeYOffset
                })`} // Rotate 90 degrees around the center, with the offset
              >
                {totalTime} {/* Round and format the time */}
              </text>
            )}
          </>
        ) : (
          <rect
            x={adjustedX}
            y={y}
            width={adjustedWidth}
            height={height}
            fill={color}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseEnter} // Ensure tooltip follows mouse movement
            onMouseLeave={handleMouseLeave}
          />
        )}

        {/* Conditionally render the number inside the bar based on bar height */}
        {shouldRenderText && (
          <text
            x={adjustedX + adjustedWidth / 2}
            y={y + height / 2 + 4}
            fill={textColor} // Dynamic text color
            fontSize={fontSize.toString()}
            textAnchor="middle"
            transform={`rotate(-90, ${adjustedX + adjustedWidth / 2}, ${
              y + height / 2
            })`}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="cursor-default"
          >
            {Math.round(data?.value)}
          </text>
        )}
      </g>
    );
  };

  const CustomLayer = ({ bars }) => (
    <>
      {bars.map((bar) => {
        const projectName = bar?.data?.id?.trim(); // Get the current bar project name (e.g., "API Development")
        const topMostProject = topMostNames[bar?.data?.indexValue?.trim()]; // Get the top-most project for this group
        const isTopMost = projectName === topMostProject; // Check if the current bar is the top-most
        console.log(
          topMostNames,
          "topMostNames",
          bar?.data?.indexValue?.trim(),
          "p name ",
          projectName,
          bar
        );
        return (
          <CustomBarComponent
            key={bar.key}
            bar={bar}
            isTopMost={isTopMost} // Pass the boolean for the top-most bar
          />
        );
      })}
    </>
  );

  // Define the number of items per page and per chunk (6 items per row, 12 items per page)
  const itemsPerPage = 12;

  // Calculate the start and end indices for slicing the array of keys
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentKeys = keys.slice(startIndex, endIndex);

  // Calculate the total number of pages based on keys length
  const totalPages = Math.ceil(keys.length / itemsPerPage);

  // Event handlers for navigating the carousel
  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i);

  const popoverContentThreeDots = (
    <div
      className="flex gap-2 items-center justify-start hover:bg-gray-200 cursor-pointer rounded p-2 "
      onClick={() => {
        const chartKey = `chart${type}`;

        dispatch(
          updateDashboard({
            key: "selectedCharts",
            value: {
              ...selectedCharts,
              [chartKey]: false, // Dynamically updating the correct chart
            },
          })
        );
      }}
    >
      <div>
        <DeleteScheduleIcon className="text-error-600" />
      </div>
      <div className="text-error-600">Delete Chart</div>
    </div>
  );

  const FormattedTime: React.FC<{ time: number }> = ({ time }) => {
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);

    return (
      <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
        {hours > 0 && (
          <>
            {hours}
            <span className="out-500-14 text-gray-500 mx-1">h</span>
          </>
        )}
        {minutes > 0 && (
          <>
            {minutes}
            <span className="out-500-14 text-gray-500 ml-1">m</span>
          </>
        )}
        {hours === 0 && minutes === 0 && (
          <>
            {0}
            <span className="out-500-14 text-gray-500 ml-1">h</span>
          </>
        )}
      </div>
    );
  };

  const tickRotationBottom = data.length > 4 ? -45 : 0;
  const isOverflow = data.length > 45;

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-xl">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <div className="out-500-16 text-black">{heading}</div>
          <Tooltip
            title={<div className="">{tooltip}</div>}
            placement={"right"}
          >
            <div>
              {/* Tooltip info icon */}
              <ReportsInfoIcon className=" text-gray-500" />
            </div>
          </Tooltip>
        </div>

        <Tooltip title="More actions" placement="topRight">
          <Popover
            content={popoverContentThreeDots}
            trigger="click"
            placement="bottomRight"
            className={`p-[5px] rounded ${
              isDotsPopoverOpen ? "bg-gray-200" : "hover:bg-gray-200"
            }`}
            arrow={false}
            onOpenChange={(visible) => setIsDotsPopoverOpen(visible)}
          >
            <DotsVerticalIcon className="text-gray-500" />
          </Popover>
        </Tooltip>
      </div>
      {showTotalValues && keys.length ? (
        <div className="relative flex flex-col gap-2] h-[52.2px]">
          <div className="flex overflow-x-auto" ref={scrollContainerRef}>
            <div className="flex gap-4 items-center whitespace-nowrap">
              {keys.map((key, i) => {
                const total = data.reduce(
                  (sum, item) => sum + (item[key] || 0),
                  0
                );
                return (
                  <div key={key} className="flex items-center gap-2">
                    <div>
                      <div className="out-500-10 text-gray-400 uppercase">
                        {key}
                      </div>

                      <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px] mt-1">
                        {indexByY === "Hours" ? (
                          <FormattedTime time={total || 0} />
                        ) : (
                          <>{total} </>
                        )}
                      </div>
                    </div>
                    {i !== keys.length - 1 && (
                      <Divider
                        type="vertical"
                        style={{ height: "40px", margin: "0" }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Arrow Buttons */}
          {showLeftArrow && (
            <button
              onClick={() => scroll("left")}
              className="absolute left-[-15px] top-1/2 transform -translate-y-1/2 bg-white p-2 h-12"
            >
              <ChevronIcon className="w-6 h-6 text-gray-500 rotate-90" />
            </button>
          )}
          {showRightArrow && (
            <button
              onClick={() => scroll("right")}
              className="absolute right-[-5px] top-1/2 transform -translate-y-1/2 bg-white p-2 mt-[-4px]"
            >
              <ChevronIcon className="w-6 h-6 text-gray-500 -rotate-90" />
            </button>
          )}
        </div>
      ) : null}
      <div className="relative bg-white flex" style={{ maxWidth: "100%" }}>
        {/* Left Axis Chart */}
        <div
          className="min-w-[50px] max-w-[50px]"
          style={{
            height: !keys?.length || !showTotalValues ? "468.2px" : "400px",
          }}
        >
          <ResponsiveBar
            data={filteredData}
            keys={["height"]}
            indexBy={indexByX}
            margin={{ top: 10, right: 0, bottom: 80, left: 20 }}
            padding={0.3}
            layout="vertical"
            colors={{ scheme: "nivo" }}
            axisLeft={{
              tickSize: 0,
              tickValues: tickValues, // Use generated tick values
              tickPadding: -25,
              tickRotation: 0,
              legend: indexByY, // Use the provided legend text
              legendOffset: -8,
              legendPosition: "middle",
            }}
            axisBottom={null}
            enableGridX={false}
            enableGridY={true}
            minValue={0}
            maxValue={maxValue}
            animate={true}
            layers={["axes"]}
            theme={{
              axis: {
                legend: {
                  text: {
                    fontFamily: "Outfit",
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "capitalize",
                    fill: "#667085",
                  },
                },
              },
              text: {
                fontFamily: "Outfit",
                fontSize: 10,
                fontWeight: 500,
                textTransform: "capitalize",
                fill: "#667085",
              },
            }}
          />
        </div>

        {/* Main Chart */}
        <div className={`${isOverflow ? "overflow-x-auto" : "w-full"}`}>
          <div
            className={`${
              !keys?.length || !showTotalValues ? "h-[468.2px]" : "h-[400px]"
            } ${isOverflow ? "w-[2300px]" : "w-full"}`}
          >
            <ResponsiveBar
              data={filteredData}
              keys={keys}
              indexBy={indexByX}
              margin={{ top: 10, right: 0, bottom: 80, left: 5 }}
              padding={0.3}
              colors={({ id }) => {
                // console.log(id, "VDay");
                return colorMapping[id];
              }}
              axisTop={null}
              axisRight={null}
              axisLeft={null}
              axisBottom={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: tickRotationBottom,
                format: (v) => {
                  const truncatedV = v.toString().substring(0, 10) + "...";
                  console.log(truncatedV, "truncatedV");
                  return v.length > 10 && data?.length > 4 ? truncatedV : v;
                },
                // legend: legendTextX,
                // legendPosition: "middle",
                // legendOffset: 73,
              }}
              // axisLeft={{
              //   tickSize: 0,
              //   tickPadding: 5,
              //   tickRotation: 0,
              //   // legend: indexByY,
              //   // legendPosition: "middle",
              //   // legendOffset: -40,
              //   // tickValues: tickValues,
              // }}
              // labelSkipWidth={12}
              // labelSkipHeight={12}
              // labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              tooltip={({ id, value, data }) => (
                <strong>
                  {id}: {value}
                </strong>
              )}
              layers={[
                "grid",
                "axes",
                // "markers",
                "legends",
                "annotations",
                CustomGridLine(0, "#969696", ""),
                CustomLayer, // Custom layer added
              ]}
              theme={{
                axis: {
                  legend: {
                    text: {
                      fontFamily: "Outfit",
                      fontSize: 12,
                      fontWeight: 500,
                      textTransform: "capitalize",
                      fill: "#667085",
                    },
                  },
                },
                text: {
                  fontFamily: "Outfit",
                  fontSize: 10,
                  fontWeight: 500,
                  textTransform: "capitalize",
                  fill: "#667085",
                },
                grid: {
                  line: {
                    stroke: "#ccc",
                    strokeWidth: 1,
                    strokeDasharray: "4 4",
                  },
                },
              }}
              enableGridY={true}
              gridYValues={tickValues}
              minValue={0}
              maxValue={maxValue}
              animate={true}
            />
          </div>
        </div>
      </div>
      <div
        className={`relative bg-gray-50  ${
          heightKey === 118 ? "py-1.5" : `py-3`
        } rounded flex flex-col justify-between flex-grow`}
      >
        <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 out-500-12 text-[#667085] capitalize">
          {legendTextX}
          <span className="out-500-12 text-[#667085]">s</span>
        </div>

        {/* Display keys for the current page (12 keys max, 6 per row) */}

        <div
          className={`flex flex-col items-center flex-grow ${
            heightKey === 118 ? "h-[86px]" : `h-[${heightKey}px]`
          } items-center`}
        >
          <div className="flex gap-4 items-center justify-center flex-grow">
            {currentKeys.slice(0, 6).map((key, index) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: colorMapping[key],
                    borderRadius: "50%",
                  }}
                />
                {/* Tooltip for truncated keys */}
                {key.length > 8 && currentKeys.length > 4 ? (
                  <Tooltip title={key}>
                    <span className="whitespace-nowrap capitalize">
                      {`${key.slice(0, 8)}...`}
                    </span>
                  </Tooltip>
                ) : (
                  <span className="whitespace-nowrap capitalize">{key}</span>
                )}
              </div>
            ))}
          </div>

          <div className="flex gap-4 items-center justify-center flex-grow">
            {currentKeys.slice(6, 12).map((key, index) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: colorMapping[key],
                    borderRadius: "50%",
                  }}
                />
                {/* Same Tooltip logic for the second line */}
                {key.length > 8 && currentKeys.length > 4 ? (
                  <Tooltip title={key}>
                    <span className="whitespace-nowrap capitalize">
                      {`${key.slice(0, 8)}...`}
                    </span>
                  </Tooltip>
                ) : (
                  <span className="whitespace-nowrap capitalize">{key}</span>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Carousel pagination with arrows and page indicators */}
        {totalPages > 1 && (
          <div className="flex items-center justify-center flex-shrink-0">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
              className={`p-2 mt-1 ${
                currentPage === 0
                  ? "cursor-not-allowed text-gray-400"
                  : "text-gray-700"
              }`}
            >
              <ChevronIcon
                className="w-6 h-6"
                style={{ transform: "rotate(90deg)" }}
              />
            </button>

            <div className="flex items-center gap-2">
              {pageNumbers.map((pageIndex) => (
                <button
                  key={pageIndex}
                  onClick={() => setCurrentPage(pageIndex)}
                  className={` rounded-full ${
                    currentPage === pageIndex
                      ? "w-2 h-2 bg-primary-600"
                      : "w-1.5 h-1.5 bg-gray-400"
                  }`}
                />
              ))}
            </div>

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
              className={`p-2 mt-[-2px] ${
                currentPage === totalPages - 1
                  ? "cursor-not-allowed text-gray-400"
                  : "text-gray-700"
              }`}
            >
              <ChevronIcon
                className="w-6 h-6"
                style={{ transform: "rotate(-90deg)" }}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DividedBarChart;
