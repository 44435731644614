import React from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { PlusIconSecondary } from "../../../Core/svgV2/PlusIcon";
import { chartsData } from "../../../Core/constants";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

const ReportsModal = ({ visible, onClose, reportType }) => {
  const dispatch = useAppDispatch();
  const { selectedCharts } = useAppSelector((state) => state.dashboard);

  const chartsList = chartsData[reportType];

  const handleChartToggle = (chartId) => {
    dispatch(
      updateDashboard({
        key: "selectedCharts",
        value: {
          ...selectedCharts,
          [chartId]: !selectedCharts[chartId],
        },
      })
    );
  };

  return (
    <CustomModal
      visible={visible}
      width="600px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14 capitalize">{reportType}</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <div className="flex flex-col gap-4 mt-4">
            <div
              className={`grid ${
                chartsList?.length === 4
                  ? "grid-cols-2 gap-4"
                  : "grid-cols-1 gap-3"
              }`}
            >
              {chartsList?.map((chart) => {
                const isSelected = selectedCharts[chart.id];

                return (
                  <div
                    key={chart.id}
                    className={`flex flex-col gap-3 rounded-lg p-4 ${
                      isSelected ? "bg-primary-50" : "bg-gray-100"
                    }`}
                  >
                    <div>
                      <img src={chart.imgSrc} alt={chart.title} />
                    </div>
                    <div>
                      <div className="out-500-14 text-black">{chart.title}</div>
                      <div className="out-300-12 text-grey-500 h-[65px]">
                        {chart.description}
                      </div>
                    </div>
                    <CustomButton
                      width={isSelected ? "90px" : "110px"}
                      text={isSelected ? "Added" : "Add Chart"}
                      onClick={() => handleChartToggle(chart.id)}
                      className={`${
                        isSelected
                          ? "whitespace-nowrap bg-primary-200 text-white"
                          : "whitespace-nowrap bg-primary-600 text-white"
                      }`}
                      icon={
                        isSelected ? (
                          <CheckIcon className="text-white" />
                        ) : (
                          <PlusIconSecondary className="text-white" />
                        )
                      }
                      iconAlignment="right"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ReportsModal;
