import React from "react";
import CustomBulletChart from "./CustomBulletChart";
import CustomGroupedBarChart from "./CustomGroupedBarChart";
import DividedBarChart from "./DividedBarChart";
import CustomCarousel, { ProjectCard } from "./CustomCarousel";
import { Spin, Divider } from "antd";
import { useGetTenantInsights } from "../../Core/redux/ReactQueryHooksV3/useReportsAPI";
import { color } from "framer-motion";
import { useAppSelector } from "../../Core/redux/hooks";

const AllChartsScreen = ({
  selectedCharts,
  overview,
  isLoading = false,
  ref1,
  ref2,
}) => {
  const {
    weeks,
    selectProjects,
    selectAssignees,
    selectTeams,
    compareTrendsWeeks,
  } = useAppSelector((state) => state.dashboard);
  const projectList = overview?.overviewProjectsData
    ?.filter((project) => selectProjects?.includes(+project?.id))
    .map((project) => {
      const {
        id,
        title,
        startDate,
        endDate,
        isActive,
        isEnded,
        projectImage,
        progress,
        activePhases,
      } = project;

      const adjustedProgress = progress === 0 ? 1 : progress;

      // Format the dates
      const start = new Date(startDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
      });
      const end = new Date(endDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      // Determine the status based on isActive and isEnded
      let status = "";
      if (isActive === 0 && isEnded === 0) {
        status = "Upcoming";
      } else if (isActive === 1 && isEnded === 0) {
        status = "Ongoing";
      } else if (isEnded === 1) {
        status = "Completed";
      }

      // Map through all active phases and assign the color and name to each
      const phases = activePhases?.map((phase) => ({
        phaseId: phase?.phaseId,
        name: phase?.name,
      }));

      return {
        id,
        title,
        progress: adjustedProgress,
        phases,
        range: `${start} - ${end}`,
        projectImage: projectImage,
        startDate,
        endDate,
        status: status,
      };
    });

  // console.log(selectedCharts, "selectedCharts here");

  // // Use renameKeys function to rename keys for selectedCharts
  // const renamedSelectedCharts = selectedCharts.map((chart) =>
  //   renameKeys(chart, keyMappings)
  // );
  // console.log(renamedSelectedCharts, "selectedCharts here re");

  // Function to conditionally render the charts
  const renderCharts = () => {
    const chartComponents = [];

    selectedCharts.forEach((chart, index) => {
      let chartComponent;
      let keysHeight;

      switch (chart.type) {
        case 1: // CustomBulletChart for 'Total Time Estimated vs Time Spent'
          chartComponent = (
            <CustomBulletChart
              type={chart.type}
              data={chart?.data}
              keys={chart?.keys}
              heading={chart?.title}
              tooltip={chart?.toolTip}
              showTotalValues={true}
              indexByX={chart?.indexByX}
              indexByY={chart?.indexByY}
              weeks={chart?.weeks}
              heightKey={21}
            />
          );
          break;
        case 7: // CustomBulletChart for 'Total Time Estimated vs Time Spent (Tasks)'
          chartComponent = (
            <CustomBulletChart
              type={chart.type}
              data={chart.data}
              keys={chart.keys}
              heading={chart.title}
              tooltip={chart.toolTip}
              showTotalValues={true}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              heightKey={21}
            />
          );
          break;

        case 2: // DividedBarChart for 'Team Time on Projects'
          keysHeight =
            chart.keys.length < 6
              ? 21
              : chart.keys.length >= 6 && chart.keys.length < 12
              ? 42
              : 118;
          chartComponent = (
            <DividedBarChart
              type={chart.type}
              key={index}
              heading={chart.title}
              tooltip={chart.toolTip}
              data={chart.data}
              keys={chart.keys}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              heightKey={keysHeight}
            />
          );
          break;
        case 5: // DividedBarChart for 'Team Allocation'
          keysHeight =
            chart.keys.length < 6
              ? 21
              : chart.keys.length >= 6 && chart.keys.length < 12
              ? 42
              : 118;
          chartComponent = (
            <DividedBarChart
              type={chart.type}
              key={index}
              heading={chart.title}
              tooltip={chart.toolTip}
              data={chart.data}
              keys={chart.keys}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              showTotalValues={false}
              heightKey={keysHeight}
            />
          );
          break;
        case 8: // DividedBarChart for 'Team Time on Projects (Selected Week(s))'
          keysHeight =
            chart.keys.length < 6
              ? 21
              : chart.keys.length >= 6 && chart.keys.length < 12
              ? 42
              : 118;
          chartComponent = (
            <DividedBarChart
              type={chart.type}
              key={index}
              heading={chart.title}
              tooltip={chart.toolTip}
              data={chart.data}
              keys={chart.keys}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              heightKey={keysHeight}
            />
          );
          break;

        case 3: // GroupedBarChart for 'Task vs Bug Ratio'
          chartComponent = (
            <CustomGroupedBarChart
              key={index}
              data={chart.data}
              keys={chart.keys}
              heading={chart.title}
              tooltip={chart.toolTip}
              showTotalValues={true}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              Total1Text="Total tasks"
              Total2Text="Total bugs"
              heightKey={21}
              chartType={chart.type}
            />
          );
          break;
        case 4: // GroupedBarChart for 'Task vs Bug Time Ratio'
          chartComponent = (
            <CustomGroupedBarChart
              key={index}
              data={chart.data}
              keys={chart.keys}
              heading={chart.title}
              tooltip={chart.toolTip}
              showTotalValues={true}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              Total1Text="Total time on tasks"
              Total2Text="Total time on bugs"
              heightKey={21}
              chartType={chart.type}
            />
          );
          break;
        case 6: // DividedBarChart for 'Total Time Spent by Teams'
          keysHeight =
            chart.keys.length < 6
              ? 21
              : chart.keys.length >= 6 && chart.keys.length < 12
              ? 42
              : 118;
          chartComponent = (
            <DividedBarChart
              type={chart.type}
              key={index}
              heading={chart.title}
              tooltip={chart.toolTip}
              data={chart.data}
              keys={chart.keys}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              showTotalValues={false}
              heightKey={keysHeight}
            />
          );
          break;
        case 9: // GroupedBarChart for 'Task vs Bug Ratio (Selected Week(s))'
          chartComponent = (
            <CustomGroupedBarChart
              key={index}
              data={chart.data}
              keys={chart.keys}
              heading={chart.title}
              tooltip={chart.toolTip}
              showTotalValues={true}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              Total1Text="Total time on tasks"
              Total2Text="Total time on bugs"
              heightKey={21}
              chartType={chart.type}
            />
          );
          break;
        case 10: // GroupedBarChart for 'Task vs Bug Time Ratio (Selected Week(s))'
          chartComponent = (
            <CustomGroupedBarChart
              key={index}
              data={chart.data}
              keys={chart.keys}
              heading={chart.title}
              tooltip={chart.toolTip}
              showTotalValues={true}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              Total1Text="Total time on tasks"
              Total2Text="Total time on bugs"
              heightKey={21}
              chartType={chart.type}
            />
          );
          break;

        default:
          break;
      }

      chartComponents.push({
        chart: chartComponent,
        dataLength: chart.data.length,
      });
    });

    // Render the charts based on data length
    const renderedCharts = [];
    for (let i = 0; i < chartComponents.length; i++) {
      const { chart, dataLength } = chartComponents[i];
      console.log(chart, "chartComponents chart");
      const nextChart = chartComponents[i + 1];

      // Check if the chart should be full width or half width
      if (dataLength > 10) {
        // Full width for larger data
        renderedCharts.push(
          <div className="w-full" key={i}>
            {chart}
          </div>
        );
      } else if (nextChart && nextChart.dataLength <= 10) {
        const currentChartHeight = chartComponents[i].chart.props.heightKey;
        const nextChartHeight = nextChart.chart.props.heightKey;
        const maxHeight = Math.max(currentChartHeight, nextChartHeight);
        console.log(
          currentChartHeight,
          nextChartHeight,
          "maxHeight",
          maxHeight,
          "nextChart",
          nextChart
        );
        // Two charts side by side
        renderedCharts.push(
          <div className="flex w-full gap-6" key={i}>
            <div
              className="flex-1"
              style={{ flex: "1 1 50%", maxWidth: "50%", overflow: "hidden" }}
            >
              {React.cloneElement(chart, { heightKey: maxHeight })}{" "}
              {/* Apply maxHeight */}
            </div>
            <div
              className="flex-1"
              style={{ flex: "1 1 50%", maxWidth: "50%", overflow: "hidden" }}
            >
              {React.cloneElement(nextChart.chart, { heightKey: maxHeight })}{" "}
              {/* Apply maxHeight */}
            </div>
          </div>
        );
        i++; // Skip the next chart since it has been rendered side by side
      } else {
        // Last chart with no partner gets full width
        renderedCharts.push(
          <div className="w-full" key={i}>
            {chart}
          </div>
        );
      }
    }

    return renderedCharts;
  };

  const filteredWeeks =
    overview?.filteredWeeks
      ?.filter((weekData) =>
        weeks.some(
          (reduxWeek) =>
            reduxWeek?.startDate === weekData?.startDate &&
            reduxWeek?.endDate === weekData?.endDate
        )
      ) // Filter weeks based on Redux weeks array
      ?.map((weekData) => {
        const { startDate, endDate, weekName } = weekData;

        const start = new Date(startDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
        });
        const end = new Date(endDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });

        return {
          week: weekName,
          dates: `${start} - ${end}`,
        };
      }) || [];

  return (
    <div>
      <div className="block">
        <div className="flex flex-col gap-6 px-6 py-4">
          <div ref={ref1}>
            {projectList?.length ? (
              <div className="out-500-16 mb-3">Projects Overview</div>
            ) : null}
            <div className="CustomCarousel ml-[-8px]">
              <CustomCarousel projects={projectList} />
            </div>
          </div>
          <div ref={ref2} className="hidden bg-[#fcfdff]">
            <div className="px-4 py-2 flex justify-start items-center gap-2 border-b border-[#dde5ec]">
              <img
                className="mb-[-4px]"
                alt="logo"
                src={"/images/v2/layout/w.svg"}
              />
              <div className="text-[#1659bf] out-300-14 leading-tight">
                Reports
              </div>
              <Divider
                type="vertical"
                style={{ height: "18px", margin: "0", color: "#dde5ec" }}
              />
              <div className="text-[#344053] out-300-14 leading-tight">
                Antarctica Global
              </div>
            </div>
            <div className=" border-b border-[#dde5ec] p-4 flex flex-col gap-2">
              <div className="flex gap-[27px] items-start">
                <div className="text-[#667084] out-300-12 capatalize leading-[18px]">
                  Weeks
                </div>
                <div className="flex flex-wrap gap-[10px]">
                  {filteredWeeks?.map((week, index) => (
                    <div
                      key={index}
                      className="text-black text-[10px] font-medium font-['Outfit'] leading-[18px]"
                    >
                      {week?.week}{" "}
                      <span className="text-[#667084] text-[10px] font-normal font-['Outfit']">
                        ({week?.dates})
                      </span>
                      ,
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex gap-[27px] items-start">
                <div className="text-[#667084] out-300-12 capatalize leading-[18px]">
                  Teams
                </div>
                <div className="flex flex-wrap gap-[10px]">
                  {overview?.teams
                    ?.filter((team) => selectTeams?.includes(+team?.id))
                    ?.map((team, index) => (
                      <div
                        key={index}
                        className="text-black text-[10px] font-medium font-['Outfit'] leading-[18px]"
                      >
                        {team?.name},
                      </div>
                    ))}
                </div>
              </div>
              <div className="flex gap-[10px] items-start">
                <div className="text-[#667084] out-300-12 capatalize leading-[18px]">
                  Assignees
                </div>
                <div className="flex flex-wrap gap-[10px]">
                  {overview?.membersPerTenant
                    ?.filter((assignee) =>
                      selectAssignees?.includes(+assignee?.id)
                    ) // Filter assignees by matching ids with selectAssignees array
                    ?.map((assignee, index) => (
                      <div
                        key={index}
                        className="text-black text-[10px] font-medium font-['Outfit'] leading-[18px]"
                      >
                        {assignee?.firstname} {assignee?.lastname},
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 p-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {projectList
                  ?.filter((project) => selectProjects?.includes(+project?.id)) // Filter projects by matching ids with selectProjects array
                  ?.map((project, index) => (
                    <ProjectCard key={index} project={project} />
                  ))}
              </div>
            </div>
          </div>
          {/* Render the charts */}
          <Spin spinning={isLoading}>
            <div className="charts-container flex flex-col gap-6">
              {renderCharts()}
            </div>
          </Spin>
        </div>
      </div>
      {/* <div ref={ref2} className="hidden bg-[#fcfdff]">
        <div className="px-4 py-2 flex justify-start items-center gap-2 border-b border-[#dde5ec]">
          <img
            className="mb-[-4px]"
            alt="logo"
            src={"/images/v2/layout/w.svg"}
          />
          <div className="text-[#1659bf] out-300-14 leading-tight">Reports</div>
          <Divider
            type="vertical"
            style={{ height: "18px", margin: "0", color: "#dde5ec" }}
          />
          <div className="text-[#344053] out-300-14 leading-tight">
            Antarctica Global
          </div>
        </div>
        <div className=" border-b border-[#dde5ec] p-4 flex flex-col gap-2">
          <div className="flex gap-[27px] items-start">
            <div className="text-[#667084] out-300-12 capatalize leading-[18px]">
              Weeks
            </div>
            <div className="flex flex-wrap gap-[10px]">
              {filteredWeeks?.map((week, index) => (
                <div
                  key={index}
                  className="text-black text-[10px] font-medium font-['Outfit'] leading-[18px]"
                >
                  {week?.week}{" "}
                  <span className="text-[#667084] text-[10px] font-normal font-['Outfit']">
                    ({week?.dates})
                  </span>
                  ,
                </div>
              ))}
            </div>
          </div>
          <div className="flex gap-[27px] items-start">
            <div className="text-[#667084] out-300-12 capatalize leading-[18px]">
              Teams
            </div>
            <div className="flex flex-wrap gap-[10px]">
              {overview?.teams?.map((team, index) => (
                <div
                  key={index}
                  className="text-black text-[10px] font-medium font-['Outfit'] leading-[18px] "
                >
                  {team?.name},
                </div>
              ))}
            </div>
          </div>
          <div className="flex gap-[10px] items-start">
            <div className="text-[#667084] out-300-12 capatalize leading-[18px]">
              Assignees
            </div>
            <div className="flex flex-wrap gap-[10px]">
              {overview?.membersPerTenant?.map((assignee, index) => (
                <div
                  key={index}
                  className="text-black text-[10px] font-medium font-['Outfit'] leading-[18px]"
                >
                  {assignee?.firstname} {assignee?.lastname},
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {projectList?.map((project, index) => (
              <ProjectCard key={index} project={project} />
            ))}
          </div>
          <div className="charts-container flex flex-col gap-4">
            {renderCharts()}
          </div>
        </div>
        <div className="flex items-center justify-center text-[#667084] text-[10px] font-normal font-['Outfit']">
          Created by antarcticaglobal.com
        </div>
      </div> */}
    </div>
  );
};

export default AllChartsScreen;
