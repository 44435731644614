import React from "react";
import { Popover, Tooltip } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import AlertIcon from "../../Core/svgV2/AlertIcon";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { PlusIconBoldGray } from "../../Core/svgV2/PlusIcon";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import useSearch from "../../Core/hooks/useSearch";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
import "antd/dist/reset.css";
import { useAppSelector } from "../../Core/redux/hooks";
import { deleteWeeks } from "../../Core/redux/api/timeEstimatesAPI";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";

export const ProjectStartDateEndDate = ({
  startDate,
  endDate,
  type,
  onChange,
}) => {
  const [date, setDate] = useState<Dayjs | null>(
    type === "Start" ? dayjs(startDate) : dayjs(endDate)
  );
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [activeField, setActiveField] = useState<"start" | "end" | null>(null);

  useEffect(() => {
    setDate(type === "Start" ? dayjs(startDate) : dayjs(endDate));
  }, [type, startDate, endDate]);

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setDate(newDate);
      const formattedDate = `${newDate.format("YYYY-MM-DD")} 10:00:00.000`;

      onChange(formattedDate);
      setIsPopoverVisible(false);
    }
  };

  const handleClick = () => {
    if (type === "End" && !startDate) return; // Prevent opening if no Start Date
    setActiveField(type === "Start" ? "start" : "end");
    setIsPopoverVisible(true);
  };

  const isActive = activeField === (type === "Start" ? "start" : "end");

  return (
    <div className="flex flex-col items-start gap-4 w-[320px] self-stretch">
      <Popover
        open={isPopoverVisible}
        placement="bottomLeft"
        arrow={false}
        onOpenChange={(open) => {
          setIsPopoverVisible(open);
          if (!open) {
            setActiveField(null);
          }
        }}
        content={
          <CustomCalendar
            selectedDate={date}
            onDateChange={handleDateChange}
            disabledBefore={
              type === "End" && startDate ? dayjs(startDate) : null
            } // Disable dates before Start Date for End Date
          />
        }
        trigger="click"
      >
        <button
          className={`flex p-1.5 items-center gap-2 self-stretch rounded border ${
            isActive ? "border-blue-500" : "border-[#DDE5ED]"
          } bg-[#F9FBFE] text-[#101828] ${
            isActive
              ? "shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05),0px_0px_0px_2px_#D1E9FF]"
              : "shadow-none"
          }`}
          onClick={handleClick}
          style={{ width: "100%" }}
        >
          <div className="flex items-center gap-2 flex-1 justify-start">
            <div className="text-[#101828] text-sm font-light leading-5">
              {date ? date.format("DD MMM YYYY") : "Select date"}
            </div>
          </div>
        </button>
      </Popover>
    </div>
  );
};

export const CustomCalendar = ({
  selectedDate,
  onDateChange,
  disabledBefore = null,
}) => {
  const [currentMonth, setCurrentMonth] = useState(() => {
    return selectedDate
      ? dayjs(selectedDate).startOf("month")
      : dayjs().startOf("month");
  });

  useEffect(() => {
    if (selectedDate) {
      setCurrentMonth(dayjs(selectedDate).startOf("month"));
    }
  }, [selectedDate]);

  const startOfMonth = currentMonth.startOf("month");
  const endOfMonth = currentMonth.endOf("month");
  const startDay = startOfMonth.day();
  const daysInMonth = endOfMonth.date();

  const totalDays = 35;
  const prevMonthDaysCount = startDay;
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const nextMonthDaysCount = totalDays - (days.length + prevMonthDaysCount);

  const prevMonthDays = Array.from({ length: prevMonthDaysCount }, (_, i) => {
    const date = startOfMonth.subtract(prevMonthDaysCount - i, "day");
    return date.date();
  });

  const nextMonthDays = Array.from(
    { length: nextMonthDaysCount },
    (_, i) => i + 1
  );

  const handlePrevMonth = () =>
    setCurrentMonth(currentMonth.subtract(1, "month"));
  const handleNextMonth = () => setCurrentMonth(currentMonth.add(1, "month"));

  const handleDateClick = (day, isNextMonth = false) => {
    const date = isNextMonth
      ? dayjs(currentMonth).add(1, "month").date(day)
      : dayjs(currentMonth).date(day);

    // Check if the selected date is after disabledBefore
    if (!disabledBefore || date.isAfter(dayjs(disabledBefore), "day")) {
      onDateChange(date);
    }
  };

  const isSelected = (day) => {
    return (
      selectedDate &&
      selectedDate.date() === day &&
      selectedDate.month() === currentMonth.month() &&
      selectedDate.year() === currentMonth.year()
    );
  };

  const isDisabled = (day, isNextMonth = false) => {
    const date = isNextMonth
      ? dayjs(currentMonth).add(1, "month").date(day)
      : dayjs(currentMonth).date(day);

    // Disable if the date is before disabledBefore
    return disabledBefore && date.isBefore(dayjs(disabledBefore), "day");
  };

  return (
    <div className="flex flex-col items-start gap-2 p-4 w-[320px]">
      <div className="flex w-[280px] justify-between items-center">
        <button
          className="flex items-center justify-center p-2.5 rounded-lg hover:bg-gray-200"
          onClick={handlePrevMonth}
        >
          <ChevronIcon className="w-5 h-5 text-gray-600 rotate-90" />
        </button>

        <div className="text-gray-700 text-center font-outfit text-base font-medium leading-6">
          {currentMonth.format("MMMM YYYY")}
        </div>

        <button
          className="flex items-center justify-center p-2.5 rounded-lg hover:bg-gray-200"
          onClick={handleNextMonth}
        >
          <ChevronIcon className="w-5 h-5 text-gray-600 -rotate-90" />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-1">
        {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day, index) => (
          <div
            key={index}
            className="w-[40px] h-[40px] flex items-center justify-center text-gray-600 font-outfit text-sm font-medium"
          >
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-1">
        {prevMonthDays?.map((day, index) => (
          <div
            key={`prev-${index}`}
            className={`w-[40px] h-[40px] flex items-center justify-center text-gray-300 font-outfit text-sm  ${
              isDisabled(day, true)
                ? "opacity-50 cursor-not-allowed"
                : "cursor-pointer"
            }`}
            onClick={() => !isDisabled(day, true) && handleDateClick(day, true)}
          >
            {day}
          </div>
        ))}
        {days?.map((day, index) => (
          <div
            key={`current-${index}`}
            className={`w-[40px] h-[40px] flex items-center justify-center rounded-full  font-outfit text-sm ${
              isSelected(day)
                ? "bg-[#EDF3F9] text-gray-500 cursor-pointer"
                : isDisabled(day)
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-500 hover:text-white cursor-pointer"
            }`}
            onClick={() => !isDisabled(day) && handleDateClick(day)}
          >
            {day}
          </div>
        ))}
        {nextMonthDays?.map((day, index) => (
          <div
            key={`next-${index}`}
            className={`w-[40px] h-[40px] flex items-center justify-center text-gray-300 font-outfit text-sm  ${
              isDisabled(day, true)
                ? "opacity-50 cursor-not-allowed"
                : "cursor-pointer"
            }`}
            onClick={() => !isDisabled(day, true) && handleDateClick(day, true)}
          >
            {day}
          </div>
        ))}
      </div>
    </div>
  );
};

export const ProjectCount = ({ count }) => {
  return (
    <div className="w-[1117px] h-6 pr-[1025px] pb-0.5 justify-start items-center inline-flex">
      <div className="self-stretch justify-start items-center gap-3 inline-flex">
        <div className="text-[#667084] text-sm font-medium font-['Outfit'] leading-tight">
          Projects
        </div>
        <div className="mix-blend-multiply justify-start items-start flex">
          <div className="px-2 py-0.5 bg-[#eff8ff] rounded-2xl justify-center items-center flex">
            <div className="text-center text-[#1659bf] text-xs font-medium font-['Outfit'] leading-[18px]">
              {count}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProjectContainer = ({ project }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        navigate(`/time-estimates/${project.id}`);
      }}
      role="button"
      className="w-[calc(20%-16px)] m-2"
    >
      <div className="h-full flex border border-[#DDE5ED] rounded-lg bg-[#F9FBFE] hover:border hover:border-[#B0C4D9] hover:mt-[1px]">
        <div className="flex h-full w-full border border-[#DDE5ED] rounded-[8px] bg-[#F9FBFE] items-center py-7 px-3 justify-between">
          <div className="flex gap-1.5 items-center overflow-hidden">
            <CustomAvatar
              title={project?.title}
              src={project?.projectImage}
              size={20}
            />
            <div className="text-[#344054] text-sm font-medium font-['Outfit'] leading-[20px] truncate max-w-[150px] overflow-hidden flex-shrink-0">
              {project?.title}
            </div>
          </div>

          <div className="items-center">
            <Tooltip
              title={"Estimates are pending"}
              placement="bottom"
              style={{
                display: "flex",
                padding: "4px 8px",
                flexDirection: "column",
                alignItems: "flex-start",
                alignSelf: "stretch",
                borderRadius: "4px",
                background: "#000000",
                color: "#ffffff",
              }}
            >
              {!project?.hasTimeEstimates && (
                <AlertIcon height="14px" width="14px" />
              )}
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProjectDropDown = ({
  project,
  onSelect = () => {
    console.log("true");
  },
}) => {
  const navigate = useNavigate();

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        onSelect();
        navigate(`/time-estimates/${project.id}`);
      }}
      role="button"
    >
      <div className="p-2 px-4 flex gap-3 items-center self-stretch border-b border-gray-100 bg-white hover:bg-[#F0F5FA] w-[400px] h-[51px]">
        <div className="flex w-5 pt-1 pr-1 pb-2 pl-1 justify-center items-center">
          <CustomAvatar
            title={project?.title}
            src={project?.projectImage}
            size={20}
            style={{ border: "1px solid #edf3f9", borderRadius: "50%" }}
          />
        </div>

        <div className="flex flex-col justify-center items-start gap-0.5 flex-1">
          <div className="self-stretch text-gray-700 text-sm font-medium leading-5">
            {project.title}
          </div>
          <div className="self-stretch text-gray-500 text-xs font-normal leading-normal font-outfit">
            {formatDate(project.startDate)} - {formatDate(project.endDate)}
          </div>
        </div>

        <div className="flex w-[75px] justify-end items-center">
          <div className="flex px-2 justify-center items-center gap-1 flex-1 rounded-full bg-[#D1E9FF]">
            <div className="text-[#165ABF] text-center text-xs font-medium leading-[18px] font-outfit">
              Ongoing
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Assignees = ({
  week,
  assignee,
  onDelete,
  onDaysChange,
  onHoursChange,
  onAssigneeChange,
  availableUsers,
}) => {
  const [isActive2, setIsActive2] = useState(false);
  const { userList } = useAppSelector((state) => state.overview);
  const { search, setSearch, filteredData } = useSearch([], ["title"]);

  const [days, setDays] = useState(assignee.noOfDays || 0);
  const [hours, setHours] = useState(assignee.noOfHours || 0);

  const initialAssignee =
    availableUsers.find((user) => user.userId === assignee.assigneeId) ||
    assignee;

  const [selectedAssignee, setSelectedAssignee] = useState(assignee);

  const selectedUser1 = userList.find(
    (user) => user.userId === selectedAssignee?.assigneeId
  );

  const [selectedUser, setSelectedUser] = useState(selectedUser1);

  const handleAssigneeSelect = (selected) => {
    setSelectedUser(selected);
    setSelectedAssignee(selected);
    onAssigneeChange(assignee.id, {
      assigneeId: selected.userId,
      id: assignee.id,
      firstname: selected.firstname || "",
      lastname: selected.lastname || "",
      noOfDays: assignee.noOfDays || 0,
      noOfHours: assignee.noOfHours || 0,
    });
    setIsActive2(false);
  };

  const displayName = selectedAssignee
    ? `${selectedAssignee?.firstname || selectedAssignee.name?.firstname} ${
        selectedAssignee?.lastname || selectedAssignee.name?.lastname
      }`
    : userList?.length > 0
    ? `${userList[0]?.firstname} ${userList[0]?.lastname}`
    : "Add Assignee";

  const placeholderText = isActive2 ? "Select Assignee" : displayName;
  const total = days * hours;

  return (
    <div className="flex h-[40px] items-center self-stretch border-b border-[#DDE5ED] bg-[#F9FBFE] hover:bg-[#F0F5FA] transition-colors group">
      <div className="w-full flex p-[5px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
        <Popover
          open={isActive2}
          placement="bottomLeft"
          onOpenChange={(open) => {
            setSearch("");
            setIsActive2(open);
          }}
          content={
            <div className="flex w-[280px] flex-col justify-center items-start gap-1">
              <div className="flex w-[280px] flex-col items-start rounded-lg border border-[#DDE5ED] bg-[#F9FBFE] shadow-lg">
                <div className="h-[275px] flex flex-col items-start self-stretch overflow-y-auto custom-scrollbar-project-bar">
                  {selectedUser && (
                    <div
                      className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE] cursor-pointer hover:bg-gray-100"
                      onClick={() => handleAssigneeSelect(selectedUser)}
                    >
                      <div className="flex w-6 h-6 justify-center items-center rounded-full">
                        <div className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat">
                          <CustomAvatar
                            src={selectedUser.profilePicture}
                            title={
                              (selectedUser.firstname || "w") +
                              " " +
                              (selectedUser.lastname || "d")
                            }
                            size={24}
                            badge
                            userId={selectedUser.id}
                            whiteText
                            color={selectedUser.profilePictureColorCode}
                            style={{
                              border: "1px solid #edf3f9",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      </div>
                      <div className="text-gray-600 text-sm font-light leading-5">
                        {selectedUser?.firstname ||
                          selectedUser.name?.firstname}{" "}
                        {selectedUser?.lastname || selectedUser.name?.lastname}
                      </div>
                    </div>
                  )}
                  {availableUsers?.map((user) => {
                    <div
                      key={user.userId}
                      className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE] cursor-pointer hover:bg-gray-100"
                      onClick={() => handleAssigneeSelect(user)}
                    >
                      <div className="flex w-6 h-6 justify-center items-center rounded-full">
                        <div className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat">
                          <CustomAvatar
                            src={user.profilePicture}
                            title={
                              (user.firstname || "w") +
                              " " +
                              (user.lastname || "d")
                            }
                            size={24}
                            badge
                            userId={user.userId}
                            whiteText
                            color={user.profilePictureColorCode}
                            style={{
                              border: "1px solid #edf3f9",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      </div>
                      <div className="text-gray-600 text-sm font-light leading-5">
                        {user.firstname} {user.lastname}
                      </div>
                    </div>;
                  })}
                </div>
              </div>
            </div>
          }
          arrow={false}
          trigger={["click"]}
        >
          <span
            className="flex w-[237.25px] h-[28px]"
            onClick={() => setIsActive2(!isActive2)}
          >
            <div
              className={`flex p-[2px_4px] items-center gap-[8px] flex-1 rounded-[4px] border transition-colors cursor-pointer ${
                isActive2
                  ? "bg-white border-[#2E90FA]"
                  : "hover:bg-white border-transparent hover:border-[#D1D5DB]"
              }`}
            >
              {!isActive2 && (
                <div className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat">
                  <CustomAvatar
                    src={selectedUser?.profilePicture}
                    title={
                      (selectedUser?.firstname || "w") +
                      " " +
                      (selectedUser?.lastname || "d")
                    }
                    size={24}
                    badge
                    userId={selectedUser?.id}
                    whiteText
                    color={selectedUser?.profilePictureColorCode}
                    style={{ border: "1px solid #edf3f9", borderRadius: "50%" }}
                  />
                </div>
              )}
              <span
                className={`text-sm font-sm leading-5 ${
                  isActive2 ? "text-gray-400" : ""
                }`}
              >
                {placeholderText}
              </span>
              <ChevronIcon
                className="cursor-pointer text-black"
                style={{
                  width: "16px",
                  height: "16px",
                  transition: "transform 0.3s ease-in-out 0s",
                  transform: isActive2 ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </div>
          </span>
        </Popover>
      </div>

      <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 border-r border-[#DDE5ED]">
        <div className="flex h-[28px] flex-1 rounded-[4px]">
          <input
            type="text"
            value={days || ""}
            placeholder="Enter no. of days"
            onChange={(e) => {
              const input = e.target.value;
              const isValid = /^[0-9]*$/.test(input);

              if (isValid) {
                const newDays = Number(input);

                const startDate = week?.startDate
                  ? dayjs(week.startDate)
                  : null;
                const endDate = week?.endDate ? dayjs(week.endDate) : null;

                if (startDate && endDate) {
                  const diffInDays = endDate.diff(startDate, "day");

                  if (newDays <= diffInDays + 1) {
                    setDays(newDays);
                    onDaysChange(assignee.id, newDays);
                  } else {
                    console.error(
                      `Number of days must be more than the difference between ${startDate.format(
                        "YYYY-MM-DD"
                      )} and ${endDate.format("YYYY-MM-DD")}`
                    );
                  }
                }
              }
            }}
            className="w-full h-full p-[4px_6px] text-[#667085] text-sm font-medium leading-[20px] hover:bg-white font-outfit placeholder-[#667085] placeholder:text-sm placeholder:font-light placeholder:leading-[20px] placeholder:font-outfit rounded-[4px] border border-transparent focus:border-[#2E90FA] hover:border-[#D1D5DB] bg-transparent outline-none transition-all focus:outline-none hover:border-[#D1D5DB] focus:border-[#2E90FA] focus:ring-0 transition-colors cursor-pointer"
          />
        </div>
      </div>

      <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
        <div className="flex h-[28px] flex-1 rounded-[4px]">
          <input
            type="text"
            value={hours || ""}
            placeholder="Enter no. of hours"
            onChange={(e) => {
              const newHours = e.target.value;

              if (/^\d*$/.test(newHours)) {
                const hoursValue = Number(newHours);

                if (hoursValue <= 24) {
                  setHours(newHours ? hoursValue : "");
                  onHoursChange(assignee.id, newHours ? hoursValue : 0);
                } else {
                  console.error("Hours cannot exceed 24.");
                }
              }
            }}
            className="w-full h-full p-[4px_6px] text-[#667085] text-sm font-medium leading-[20px] font-outfit hover:bg-white placeholder-[#667085] placeholder:text-sm placeholder:font-light placeholder:leading-[20px] placeholder:font-outfit rounded-[4px] border border-transparent focus:border-[#2E90FA] hover:border-[#D1D5DB] bg-transparent outline-none transition-all focus:outline-none hover:border-[#D1D5DB] focus:border-[#2E90FA] focus:ring-0 transition-colors cursor-pointer"
          />
        </div>
      </div>

      <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch">
        <p className="w-full text-[#344054] text-sm font-medium leading-[20px] font-outfit">
          {total || "0"}
        </p>
        <Tooltip
          title={"Remove"}
          placement="bottom"
          style={{
            display: "flex",
            padding: "4px 8px",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "stretch",
            borderRadius: "4px",
            background: "#000000",
            color: "#ffffff",
          }}
        >
          <div
            className="w-[14px] h-[14px] flex-col items-center gap-[4px] group-hover:block hidden cursor-pointer"
            onClick={onDelete}
          >
            <DeleteIcon
              className="text-red-600"
              style={{
                width: "14px",
                height: "14px",
                flexShrink: 0,
                stroke: "var(--Error-600, #D92D20)",
              }}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export const DisplayWeeks = ({
  week,
  isLastWeek,
  addAssigneeToWeek,
  onDeleteAssignee,
  weeksWithDetails,
  isDeleted,
  onSwitchWeeks,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isActive3, setIsActive3] = useState(false);
  const { projectId } = useParams();
  const { userList } = useAppSelector((state) => state.overview);
  const [isHovered, setIsHovered] = useState(false);
  const [isAddAssigneeActive, setIsAddAssigneeActive] = useState(true);

  const [isAssigneesVisible, setIsAssigneesVisible] = useState(isLastWeek);
  const { search, setSearch, filteredData } = useSearch([], ["title"]);
  const [assigneesData, setAssigneesData] = useState([]);

  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    if (week?.assignees) {
      setAssigneesData(
        week.assignees.map((assignee) => ({
          assigneeId: assignee.assigneeId,
          id: assignee.id,
          name: assignee.assignee_name || assignee.name,
          noOfDays: Number(assignee.noOfDays),
          noOfHours: Number(assignee.noOfHours),
        }))
      );
    }
  }, [week?.id]);

  useEffect(() => {
    const totalHours = calculateTotalHoursPerWeek();
    setTotalHours(totalHours);
  }, [assigneesData]);

  const handleDaysChange = (id, value) => {
    setAssigneesData((prevData) => {
      const updatedData = prevData.map((assignee) => {
        const updatedAssignee =
          assignee.id === id
            ? { ...assignee, noOfDays: Number(value) }
            : assignee;

        return updatedAssignee;
      });

      addAssigneeToWeek(week.id, updatedData);
      return updatedData;
    });
  };

  const handleHoursChange = (id, value) => {
    setAssigneesData((prevData) => {
      const updatedData = prevData.map((assignee) =>
        assignee.id === id
          ? { ...assignee, noOfHours: Number(value) }
          : assignee
      );

      addAssigneeToWeek(week.id, updatedData);
      return updatedData;
    });
  };

  const handleAssigneeChange = (id, selectedAssignee) => {
    setAssigneesData((prevData) => {
      const updatedData = prevData.map((assignee) =>
        assignee.id === id
          ? {
              ...assignee,
              assigneeId: selectedAssignee.assigneeId,
              name: {
                firstname: selectedAssignee.firstname || "",
                lastname: selectedAssignee.lastname || "",
              },
              noOfDays: assignee.noOfDays || 0,
              noOfHours: assignee.noOfHours || 0,
            }
          : assignee
      );

      addAssigneeToWeek(week.id, updatedData);
      return updatedData;
    });
  };

  const calculateTotalHoursPerWeek = () => {
    return assigneesData?.reduce(
      (total, assignee) => total + assignee.noOfDays * assignee.noOfHours,
      0
    );
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
      timeZone: "UTC",
    };
    return date.toLocaleDateString("en-GB", options);
  };

  const formatDate2 = (dateString: string): string => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      timeZone: "UTC",
    };
    return date.toLocaleDateString("en-GB", options);
  };

  const assignedUserIds = assigneesData.map((a) => a.assigneeId);
  const availableUsers = userList.filter(
    (user) => !assignedUserIds.includes(user.userId)
  );

  const addAssignee = () => {
    if (!availableUsers || availableUsers.length === 0) {
      setIsAddAssigneeActive(false);
      console.warn("No available users to assign.");
      return;
    }
    const newAssignee = {
      assigneeId: availableUsers[0]?.userId,
      id: `temp-${Date.now()}`,
      name: {
        firstname: availableUsers[0]?.firstname,
        lastname: availableUsers[0]?.lastname,
      },
      noOfDays: 0,
      noOfHours: 0,
    };
    // setAssigneesData((prev) => {
    //     const isAssigneePresent = prev.some(assignee => assignee.assigneeId === newAssignee.assigneeId);

    //     if (isAssigneePresent) {
    //       console.warn("Assignee is already added.");
    //       return prev;
    //     }

    //     const updatedData = [...prev, newAssignee];
    //     addAssigneeToWeek(week.id, updatedData);
    //     return updatedData;
    // });

    setAssigneesData((prev) => {
      const updatedData = [...prev, newAssignee];
      addAssigneeToWeek(week.id, updatedData);
      return updatedData;
    });

    if (availableUsers.length === 1) {
      setIsAddAssigneeActive(false);
      console.warn("No available users to assign.");
      return;
    }
  };

  const handleDeleteWeek = async (week) => {
    try {
      const numericProjectId = parseInt(projectId, 10);

      await dispatch(
        deleteWeeks({
          projectId: numericProjectId,
          deletedWeeks: [{ id: week.id }],
        }) as any
      );

      queryClient.invalidateQueries("projectWeeks");
    } catch (error) {
      console.error("Error deleting week:", error);
    }
  };

  const handleSwitch = (selectedWeekId) => {
    console.log(weeksWithDetails, "weeksWithDetails updateWeeks");
    onSwitchWeeks(week.id, selectedWeekId);
  };

  const handleDeleteAssigneeMain = (id) => {
    setAssigneesData((prevData) => {
      const updatedData = prevData.filter((assignee) => assignee.id !== id);

      const deletedAssignee = prevData.find((assignee) => assignee.id === id);

      const existingAssignees = prevData
        .filter((assignee) => !assignee.id.startsWith("temp-"))
        .map((assignee) => ({
          weekId: week.id,
          assigneeId: assignee.id,
          projectId: projectId,
        }));

      if (onDeleteAssignee) {
        onDeleteAssignee(existingAssignees);
      }

      addAssigneeToWeek(week.id, updatedData);
      return updatedData;
    });
  };

  const handleDeleteAssignee = (id) => {
    setAssigneesData((prevData) => {
      const updatedData = prevData.filter((assignee) => assignee.id !== id);

      const deletedAssignee = prevData.find((assignee) => assignee.id === id);

      const deletedAssignees =
        deletedAssignee && !deletedAssignee.id.startsWith("temp-")
          ? [
              {
                weekId: week.id,
                assigneeId: deletedAssignee.id,
                projectId: projectId,
              },
            ]
          : [];

      if (onDeleteAssignee) {
        onDeleteAssignee(deletedAssignees);
      }

      addAssigneeToWeek(week.id, updatedData);
      return updatedData;
    });
  };

  const getUserNameById = (userId) => {
    const user = userList.find((user) => user.userId === userId);
    return user ? `${user.firstname} ${user.lastname}` : "";
  };

  return (
    <div className="flex w-full flex-col items-start">
      <div className="flex h-[42px] p-[10px_24px] items-center gap-[8px] self-stretch border-b border-gray-200 bg-white">
        <Tooltip
          title={"Project Week"}
          placement="bottom"
          style={{
            display: "flex",
            padding: "4px 8px",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "stretch",
            borderRadius: "4px",
            background: "#000000",
            color: "#ffffff",
          }}
        >
          <div className="flex justify-center items-center cursor-pointer">
            <div className="flex justify-center items-center">
              <div className="flex justify-center items-center rounded-full bg-[#165ABF] min-w-[18px] min-h-[18px] px-2 py-1">
                <div className="text-[#F9FBFE] text-center font-outfit text-xs font-medium leading-[18px]">
                  {week?.count}
                </div>
              </div>
            </div>
          </div>
        </Tooltip>

        {/* weeks  */}
        <Popover
          open={isActive3}
          placement="bottomLeft"
          onOpenChange={(open) => {
            setSearch("");
            setIsActive3(open);
          }}
          content={
            <div className="flex w-[280px] flex-col justify-center items-start gap-1">
              <div className="flex w-[280px]  flex-col items-start rounded-lg border border-[#DDE5ED] bg-[#F9FBFE] shadow-lg">
                <div className="flex h-[307px] flex-col items-start self-stretch">
                  <div className="h-[307px] flex flex-col items-start self-stretch overflow-y-auto custom-scrollbar-project-bar">
                    {weeksWithDetails?.map((week1, index) => (
                      <div
                        key={index}
                        className={`h-[51px] flex p-2.5 items-center gap-3 self-stretch border-b hover:bg-[#F0F5FA] transition-colors cursor-pointer ${
                          week?.week === week1.weekName ? "bg-[#EFF8FF]" : ""
                        }`}
                        onClick={() => handleSwitch(week1.id)}
                        // className="h-[51px] flex p-2.5 items-center gap-3 self-stretch border-b border-[#D1E9FF] bg-[#EFF8FF]"
                        // className={`h-[51px] flex p-2.5 items-center gap-3 self-stretch border-b bg-white hover:bg-[#F0F5FA] transition-colors cursor-pointer`}
                      >
                        <div className="flex flex-col justify-center items-start gap-0.5 flex-1">
                          <div className="text-gray-700 text-sm font-medium leading-5">
                            {week1.weekName}
                          </div>
                          <div className="self-stretch text-gray-500 text-xs font-normal leading-normal font-outfit">
                            {/* {formatDate(week.startDate)} - {formatDate(week.endDate)} */}
                            {week1.startDate && week1.endDate
                              ? `${formatDate2(week1.startDate)} - ${formatDate(
                                  week1.endDate
                                )}`
                              : ""}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          }
          arrow={false}
          trigger={["click"]}
        >
          <span
            onClick={() => setIsActive3(!isActive3)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              className={`flex items-start rounded-[8px] ${
                isActive3
                  ? "border border-blue-500 bg-transparent"
                  : "border border-transparent bg-transparent hover:bg-gray-100"
              }`}
            >
              <div className="flex px-[10px] py-[5px] justify-center items-center gap-[8px] rounded-[4px]">
                {/* <div className="text-[#292927] text-sm font-medium leading-[20px] font-outfit"> */}
                {/* <div className={`text-sm font-medium leading-[20px] font-outfit ${isDeleted ? 'text-[var(--Error-600, #D92D20)]' : 'text-[#292927]'}`}> */}
                <div
                  className={`text-sm font-medium leading-[20px] font-outfit ${
                    isDeleted ? "text-red-500" : "text-[#292927]"
                  }`}
                >
                  {week?.week}
                </div>
                {/* <p className="text-[#667085] text-xs font-light leading-[18px] font-outfit"> */}
                <p
                  className={`text-xs font-light leading-[18px] font-outfit ${
                    isDeleted ? "text-red-500" : "text-[#667085]"
                  }`}
                >
                  {/* {formatDate(week?.startDate)} - {formatDate(week?.endDate)} */}
                  {week?.startDate && week?.endDate
                    ? `(${formatDate2(week?.startDate)} - ${formatDate(
                        week?.endDate
                      )})`
                    : ""}
                </p>

                <ChevronIcon
                  // className="cursor-pointer text-black"
                  className={`cursor-pointer transition-colors ${
                    isDeleted ? "text-red-500" : "text-black"
                  }`}
                  style={{
                    width: "20px",
                    height: "20px",
                    transition: "transform 0.3s ease-in-out 0s",
                    transform: isActive3 ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </div>
            </div>
          </span>
        </Popover>

        {/* gap */}
        <div
          className="flex h-[20px] items-center gap-[8px] flex-1"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={(e) => {
            e.stopPropagation();
            if (isDeleted && isHovered) {
              handleDeleteWeek(week);
            }
          }}
        >
          {isDeleted && isHovered && (
            <div className="flex h-[20px] items-center gap-[8px] flex-1">
              <DeleteIcon
                className="text-red-600"
                style={{
                  width: "14px",
                  height: "14px",
                  flexShrink: 0,
                  stroke: "var(--Error-600, #D92D20)",
                }}
              />
            </div>
          )}
        </div>

        {week && week.updatedBy && (
          <div
            className="text-[#667085] text-[10px] font-normal font-outfit"
            style={{ fontFamily: "Outfit", lineHeight: "normal" }}
          >
            Added by {getUserNameById(week.updatedBy)} on{" "}
            {formatDate(week.updatedAt)}
          </div>
        )}

        {!isAssigneesVisible && (
          <>
            <div className="flex p-1 px-2 items-center gap-2 rounded-[13px] bg-[#D1E9FF]">
              <div
                className="text-[#101828] text-center text-xs font-medium leading-[18px]"
                style={{ fontFamily: "Outfit, sans-serif" }}
              >
                TOTAL
              </div>
              <div
                className="text-[#101828] text-center text-xs font-medium leading-[18px]"
                style={{ fontFamily: "Outfit, sans-serif" }}
              >
                {totalHours}h
              </div>
            </div>
          </>
        )}

        {/* chevronButton */}
        <div className="flex items-start rounded-[8px]">
          <div className="flex p-[2px] justify-center items-center gap-[8px] rounded-[4px] border border-[#C0CBD7] bg-[#F9FBFE]">
            <ChevronIcon
              style={{
                width: "20px",
                height: "20px",
                transition: "transform 0.5s ease-in-out",
                transform: isAssigneesVisible
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              }}
              onClick={() => setIsAssigneesVisible(!isAssigneesVisible)}
            ></ChevronIcon>
          </div>
        </div>
      </div>

      {isAssigneesVisible && (
        <>
          {/* Header */}
          <div className="flex h-[24px] items-center self-stretch border-b border-[#DDE5ED] bg-[#F0F5FA]">
            <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
              <p className="text-[#667085] text-xs text-center font-medium leading-[18px] font-outfit">
                Assignee
              </p>
            </div>

            <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
              <p className="text-[#667085] text-xs text-center font-medium leading-[18px] font-outfit">
                No. of Days
              </p>
            </div>

            <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
              <p className="text-[#667085] text-xs text-center font-medium leading-[18px] font-outfit">
                No. of Hours/Day
              </p>
            </div>

            <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch">
              <p className="text-[#667085] text-xs text-center font-medium leading-[18px] font-outfit">
                Total hours
              </p>
            </div>
          </div>
          {assigneesData.map((assignee) => {
            return (
              <Assignees
                key={assignee.id}
                assignee={assignee}
                availableUsers={availableUsers}
                onDelete={() => handleDeleteAssignee(assignee.id)}
                onDaysChange={(id, value) => handleDaysChange(id, value)}
                onHoursChange={(id, value) => handleHoursChange(id, value)}
                onAssigneeChange={(id, selectedAssignee) =>
                  handleAssigneeChange(id, selectedAssignee)
                }
                week={week}
              />
            );
          })}

          {/* Add Assignee */}
          {isAddAssigneeActive && assigneesData.length < availableUsers.length && (
            <div className="flex h-[32px] items-center self-stretch bg-[#F9FBFE] ">
              <div className="flex h-[36px] p-[12px] px-[24px] items-center gap-[12px] hover:text-black flex-1 ">
                <div
                  className="flex items-start gap-[12px]  hover:text-black cursor-pointer"
                  onClick={addAssignee}
                >
                  <PlusIconBoldGray
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  ></PlusIconBoldGray>
                  <p className="text-[#667085] font-outfit text-sm font-medium leading-5 hover:text-black">
                    Add Assignee
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex h-[24px] items-center self-stretch bg-[#D1E9FF]">
            <div className="flex p-[13px_24px] items-center gap-[12px] flex-1 self-stretch">
              <div
                className="text-[#101828] text-[12px] font-medium leading-[18px]"
                style={{ fontFamily: "Outfit, sans-serif" }}
              >
                TOTAL
              </div>
            </div>

            <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch ">
              {" "}
            </div>
            <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch "></div>

            <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch">
              <div
                className="text-[#101828] text-[14px] font-medium leading-[20px] text-center"
                style={{ fontFamily: "Outfit, sans-serif" }}
              >
                {calculateTotalHoursPerWeek()}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
