import React, { useEffect, useState } from "react";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";
import { Popover, Tooltip } from "antd";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { useNavigate, useParams } from "react-router-dom";
import { PlusIconBold, PlusIconBoldBlack } from "../../Core/svgV2/PlusIcon";
import { CopyOutlineIcon } from "../../Core/svgV2/CopyIcon";
import ProjectDateModal from "./Modals/ProjectDateModal";
import { ProjectDropDown, DisplayWeeks } from "./TimeEstimatesReusable";
import EstimatesMissingModal from "./Modals/EstimatesMissingModal";
import { EditThreeIcon } from "../../Core/svgV2/Edit02Icon";
import { useGetProjectDetails } from "../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import {
  useGetProjectWeeks,
  useGetTenantProjects,
} from "../../Core/redux/ReactQueryHooksV3/userTimeEstimatesAPI";
import { useDispatch } from "react-redux";
import {
  addAssignees,
  addProjectWeeks,
  deleteAssignees,
  deleteWeeks,
  editProjectDates,
  updateAssignees,
  updateWeeks,
} from "../../Core/redux/api/timeEstimatesAPI";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { setPendingTimeEstimates } from "../../Core/redux/slices/timeEstimates.slice";
import { useAppSelector } from "../../Core/redux/hooks";
import AlertIcon from "../../Core/svgV2/AlertIcon";
import { useQueryClient } from "react-query";
import { motion } from "framer-motion";
import { CrossIconSecondary } from "../../Core/svgV2/CrossIcon";

const ProjectTimeEstimatesScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { projectId } = useParams();

  const { data: projectDetails } = useGetProjectDetails(projectId);
  const { data: weeksData } = useGetProjectWeeks(projectId);
  const { pendingTimeEstimates } = useAppSelector(
    (state) => state.timeEstimates
  );

  const [isActive, setIsActive] = useState(false);
  const [areButtonsVisible, setAreButtonsVisible] = useState(true);

  const [projectDateModal, setProjectDateModal] = useState(false);
  const [discardModal, setDiscardModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [dateChangeModalOne, setDateChangeModalOne] = useState(false);
  const [dateChangeModalTwo, setDateChangeModalTwo] = useState(false);
  const [dateChangeModalThree, setDateChangeModalThree] = useState(false);

  const [apiPayload, setApiPayload] = useState(null);

  const [projectStartDate, setProjectStartDate] = useState(null);
  const [projectEndDate, setProjectEndDate] = useState(null);

  const [weeksWithAssigneesAndNames, setWeeksWithAssigneesAndNames] = useState(
    []
  );
  const [newlyAddedAssignees, setNewlyAddedAssignees] = useState([]);
  const [weeksWithDetails, setWeeksWithDetails] = useState([]);

  const [deletedAssigneesPayload, setDeletedAssigneesPayload] = useState([]);
  const [overallTotalHours, setOverallTotalHours] = useState(0);
  const [search, setSearch] = useState("");

  const [firstWeekName, setFirstWeekName] = useState(null);
  const [lastWeekName, setLastWeekName] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  const calculateOverallTotalHours = () => {
    let totalHours = 0;
    weeksWithAssigneesAndNames.forEach((week) => {
      week.assignees.forEach((assignee) => {
        const hours = parseInt(assignee.noOfHours, 10) || 0;
        const days = parseInt(assignee.noOfDays, 10) || 0;
        totalHours += hours * days;
      });
    });
    setOverallTotalHours(totalHours);
  };

  useEffect(() => {
    calculateOverallTotalHours();
  }, [weeksWithAssigneesAndNames]);

  useEffect(() => {
    let count = 1;
    weeksWithAssigneesAndNames.forEach((week) => {
      week["count"] = count;
      count = count + 1;
    });
  }, [weeksWithAssigneesAndNames]);

  useEffect(() => {
    const { firstWeekName, lastWeekName } = calculateRemainingWeeks(
      weeksWithDetails,
      projectEndDate
    );

    setFirstWeekName(firstWeekName);
    setLastWeekName(lastWeekName);
  }, [projectId, weeksWithDetails]);

  useEffect(() => {
    if (projectDetails) {
      const startDate = new Date(projectDetails?.projectDetails2?.startDate);
      const utcStartDate = new Date(
        Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate(),
          0,
          0,
          0
        )
      );
      setProjectStartDate(utcStartDate);

      const endDate = new Date(projectDetails?.projectDetails2?.endDate);
      const utcEndDate = new Date(
        Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate(),
          0,
          0,
          0
        )
      );
      setProjectEndDate(utcEndDate);
    }
  }, [projectDetails]);

  useEffect(() => {
    createWeeksFromDates(projectStartDate, projectEndDate);
  }, [
    projectId,
    projectDetails,
    projectStartDate,
    projectEndDate,
    // weeksWithDetails,
  ]);

  useEffect(() => {
    if (weeksData && Array.isArray(weeksData) && weeksData.length > 0) {
      setWeeksWithAssigneesAndNames(weeksData);
    } else if (projectStartDate) {
      const newWeek = createDefaultWeek();
      setWeeksWithAssigneesAndNames([newWeek]);
    }
  }, [weeksData, projectStartDate]);

  useEffect(() => {
    const currentDate = new Date();
    const endDate = new Date(projectEndDate);

    const lastWeek = weeksWithAssigneesAndNames.reduce((latest, week) => {
      const weekEndDate = new Date(week.endDate);
      return weekEndDate > latest ? weekEndDate : latest;
    }, new Date(0));

    const shouldSetPending =
      endDate > lastWeek &&
      !(
        lastWeek >= currentDate &&
        lastWeek <= new Date(currentDate.setDate(currentDate.getDate() + 6))
      );

    dispatch(setPendingTimeEstimates(shouldSetPending));
  }, [projectEndDate, weeksWithAssigneesAndNames, dispatch]);

  const createWeeksFromDates = (projectStartDate, projectEndDate) => {
    if (
      !(projectStartDate instanceof Date) ||
      !(projectEndDate instanceof Date) ||
      projectStartDate > projectEndDate
    ) {
      console.error("Invalid startDate or endDate");
      return;
    }

    const weeks = [];
    let currentStartDate = new Date(projectStartDate);

    // Adjusting to start on Monday if the start date falls on a weekend
    if (currentStartDate.getDay() === 6) {
      currentStartDate.setDate(currentStartDate.getDate() + 2);
    } else if (currentStartDate.getDay() === 0) {
      currentStartDate.setDate(currentStartDate.getDate() + 1);
    }

    while (currentStartDate <= projectEndDate) {
      const endDate = new Date(currentStartDate);

      // Find how many days until Friday
      const dayOfWeek = currentStartDate.getDay();
      const daysUntilFriday = (5 - dayOfWeek + 7) % 7;
      endDate.setDate(currentStartDate.getDate() + daysUntilFriday);

      // Limit endDate to the projectEndDate
      if (endDate > projectEndDate) {
        endDate.setTime(projectEndDate.getTime());
      }

      const weekNumber = getWeekNumber(currentStartDate);
      const yearShort = `'${String(currentStartDate.getFullYear()).slice(-2)}`; // Get last 2 digits of the year
      const weekName = `Week ${weekNumber} ${yearShort}`;

      // Prevent duplicate week entries
      if (!weeks.some((week) => week.weekName === weekName)) {
        weeks.push({
          weekName,
          startDate: currentStartDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
        });
      }

      // Move to the next week
      currentStartDate = new Date(endDate);
      currentStartDate.setDate(currentStartDate.getDate() + 3); // Move to the next Monday
    }
    // console.log(weeks, "createWeeksFromDates");
    setWeeksWithDetails(weeks);
  };

  const getWeekNumber = (date) => {
    const start = new Date(date.getFullYear(), 0, 1); // First day of the year
    const days = Math.floor(
      (date.getTime() - start.getTime()) / (24 * 60 * 60 * 1000)
    );

    const weekNumber = Math.ceil((days + start.getDay() + 1) / 7);
    const firstWeekDay = start.getDay();
    return weekNumber + (firstWeekDay > 4 ? 1 : 0);
  };

  const handleDeletedAssignees = (payload) => {
    setDeletedAssigneesPayload((prevPayload) => [...prevPayload, ...payload]);
  };

  const updateWeeksWithNewDates = (
    newStartDate,
    newEndDate,
    prevWeeks: any[]
  ) => {
    const newWeeks = [];
    const updatedWeeks = [];
    const deletedWeeks = [];
    const neewWeeksPayload = [];

    const newStartDateObj = new Date(newStartDate);
    const newEndDateObj = new Date(newEndDate);

    const firstWeekEndDate = new Date(newStartDateObj);
    firstWeekEndDate.setDate(
      firstWeekEndDate.getDate() + (5 - firstWeekEndDate.getDay())
    );
    newWeeks.push({
      id: `temp-${Date.now()}`,
      week: `Week ${getWeekNumber(newStartDateObj)}`,
      startDate: newStartDateObj.toISOString(),
      endDate: firstWeekEndDate.toISOString(),
      totalHours: null,
      assignees: [],
    });

    const currentStartDate = new Date(firstWeekEndDate);
    currentStartDate.setDate(currentStartDate.getDate() + 3);

    while (currentStartDate < newEndDateObj) {
      const weekEndDate = new Date(currentStartDate);
      weekEndDate.setDate(currentStartDate.getDate() + 4);

      if (weekEndDate > newEndDateObj) {
        weekEndDate.setTime(newEndDateObj.getTime());
      }

      newWeeks.push({
        id: `temp-${Date.now()}`,
        week: `Week ${getWeekNumber(currentStartDate)}`,
        startDate: currentStartDate.toISOString(),
        endDate: weekEndDate.toISOString(),
        totalHours: null,
        assignees: [],
      });

      currentStartDate.setDate(currentStartDate.getDate() + 7);
    }

    const lastWeek = newWeeks[newWeeks.length - 1];
    lastWeek.endDate = newEndDateObj.toISOString();

    const prevWeeksMap = new Map(prevWeeks?.map((week) => [week.week, week]));

    const lastPrevWeekNumber = Math.max(
      ...prevWeeks.map((week) => parseInt(week.week.split(" ")[1]))
    );

    const filteredNewWeeks = newWeeks.filter((week) => {
      const weekNumber = parseInt(week.week.split(" ")[1]);
      return !prevWeeksMap.has(week.week) && weekNumber <= lastPrevWeekNumber;
    });

    newWeeks.forEach((week) => {
      if (prevWeeksMap.has(week.week)) {
        const prevWeek = prevWeeksMap.get(week.week);

        if (!prevWeek.id.startsWith("temp")) {
          if (
            prevWeek.startDate !== week.startDate ||
            prevWeek.endDate !== week.endDate
          ) {
            updatedWeeks.push({
              ...prevWeek,
              startDate: week.startDate,
              endDate: week.endDate,
            });
          }
          prevWeeksMap.delete(week.week);
        }
      }
    });

    for (const week of Array.from(prevWeeksMap.values())) {
      // deletedWeeks.push(week.id);
      if (!week.id.startsWith("temp")) {
        deletedWeeks.push(week.id);
      }
    }

    neewWeeksPayload.push(...filteredNewWeeks, ...updatedWeeks);

    const payload = {
      projectId: projectId,
      projectStartDate: newStartDate,
      projectEndDate: newEndDate,
      newWeeks: filteredNewWeeks,
      updatedWeeks: updatedWeeks,
      deletedWeeks: deletedWeeks,
    };
    setWeeksWithAssigneesAndNames(neewWeeksPayload);

    return payload;
  };

  const handleApiCalls = async (payload, shouldDelete) => {
    try {
      const numericProjectId = Number(payload.projectId);

      await dispatch(
        editProjectDates({
          projectId: numericProjectId,
          updateObj: {
            startDate: payload.projectStartDate,
            endDate: payload.projectEndDate,
          },
        }) as any
      ).unwrap();

      if (shouldDelete && payload.deletedWeeks.length > 0) {
        await dispatch(
          deleteWeeks({
            projectId: numericProjectId,
            deletedWeeks: payload.deletedWeeks.map((weekId) => ({
              id: weekId,
            })),
          }) as any
        ).unwrap();
      } else {
        setDeletedAssigneesPayload(payload.deletedWeeks);
      }

      if (payload.newWeeks.length > 0) {
        await dispatch(
          addProjectWeeks({
            projectId: numericProjectId,
            weeks: payload.newWeeks,
          }) as any
        ).unwrap();
      }

      if (payload.updatedWeeks.length > 0) {
        await dispatch(
          updateWeeks({
            projectId: numericProjectId,
            existingWeeks: payload.updatedWeeks,
          }) as any
        ).unwrap();
      }

      queryClient.invalidateQueries("projectWeeks");
      queryClient.invalidateQueries("projectDetails");
    } catch (error) {
      console.error("Error during API calls:", error);
    }
  };

  const handleDateSave = (newStartDate, newEndDate) => {
    setTempStartDate(new Date(newStartDate));
    setTempEndDate(new Date(newEndDate));

    const payload = updateWeeksWithNewDates(
      new Date(newStartDate),
      new Date(newEndDate),
      weeksWithAssigneesAndNames
    );
    setApiPayload(payload);

    if (payload.deletedWeeks.length > 0) {
      setDateChangeModalTwo(true);
    } else {
      setDateChangeModalOne(true);
    }
  };

  const addAssigneeToWeek = (weekId, updatedAssignees) => {
    setWeeksWithAssigneesAndNames((prevWeeks) => {
      return prevWeeks.map((week) => {
        if (week.id === weekId) {
          const newAssignees = updatedAssignees.filter((assignee) =>
            assignee.id.startsWith("temp-")
          );

          const formattedNewAssignees = newAssignees.map((assignee) => ({
            userId: parseInt(assignee.id.replace("temp-", "")),
            days: assignee.noOfDays,
            hours: assignee.noOfHours,
          }));

          setNewlyAddedAssignees((prev) => [
            ...prev,
            ...formattedNewAssignees.map((a) => ({
              projectId: projectId,
              weekId: week.id,
              assignees: [a],
            })),
          ]);

          return { ...week, assignees: updatedAssignees };
        }
        return week;
      });
    });
  };

  const createDefaultWeek = () => {
    if (!projectStartDate) {
      return null;
    }

    const startDate = new Date(projectStartDate);

    const startDay = startDate.getDay();
    const adjustedStartDate = new Date(projectStartDate);

    if (startDay === 6) {
      adjustedStartDate.setDate(adjustedStartDate.getDate() + 2);
    } else if (startDay === 0) {
      adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);
    }

    const weekNumber = getWeekNumber(projectStartDate);

    const endDate = new Date(projectStartDate);
    const dayOfWeek = projectStartDate.getDay();
    const daysUntilFriday = (5 - dayOfWeek + 7) % 7;

    endDate.setDate(projectStartDate.getDate() + daysUntilFriday);

    if (endDate > projectEndDate) {
      endDate.setTime(projectEndDate.getTime());
    }
    return {
      id: `temp-${Date.now()}`,
      week: `Week ${weekNumber}`,
      startDate: adjustedStartDate.toISOString(),
      endDate: endDate.toISOString(),
      totalHours: null,
      assignees: [],
    };
  };

  const addWeek = () => {
    const lastWeek =
      weeksWithAssigneesAndNames[weeksWithAssigneesAndNames.length - 1];
    const newStartDate = new Date(lastWeek.endDate);
    newStartDate.setDate(newStartDate.getDate() + 3);

    if (newStartDate > projectEndDate) {
      setAreButtonsVisible(false);
      console.warn("disabled the add weeks and add and duplicates");
      return;
    }

    const newEndDate = new Date(newStartDate);
    const daysUntilFriday = (5 - newStartDate.getDay() + 7) % 7;
    newEndDate.setDate(newStartDate.getDate() + daysUntilFriday);

    if (newEndDate > projectEndDate) {
      newEndDate.setTime(projectEndDate.getTime());
    }

    const newWeekNumber = getWeekNumber(newStartDate);
    const newWeek = {
      id: `temp-${Date.now()}`,
      week: `Week ${newWeekNumber}`,
      startDate: newStartDate.toISOString(),
      endDate: newEndDate.toISOString(),
      totalHours: null,
      assignees: [],
    };

    setWeeksWithAssigneesAndNames((prevWeeks) => [...prevWeeks, newWeek]);

    const nextStartDate = new Date(newEndDate);
    nextStartDate.setDate(nextStartDate.getDate() + 3);

    if (newEndDate >= nextStartDate || nextStartDate > projectEndDate) {
      setAreButtonsVisible(false);
      console.warn(
        "disabled the add weeks and add and duplicates due to date overlap"
      );
      return;
    }
  };

  const separateWeeks = () => {
    const existingWeeks = weeksWithAssigneesAndNames.filter(
      (week) => !week.id.startsWith("temp-")
    );
    const newWeeks = weeksWithAssigneesAndNames.filter((week) =>
      week.id.startsWith("temp-")
    );

    return { existingWeeks, newWeeks };
  };

  const createPayloadForNewAssigneesAndRemoveFromWeeks = () => {
    const existingWeeks = weeksWithAssigneesAndNames.filter(
      (week) => !week.id.startsWith("temp-")
    );
    const newAssigneesPayload = [];
    const presentAssigneesPayload = [];

    const updatedWeeks = existingWeeks.map((week) => {
      const newAssignees = week.assignees
        .filter((assignee) => assignee.id.startsWith("temp-"))
        .map((assignee) => ({
          userId: assignee.assigneeId,
          noOfDays: assignee.noOfDays,
          noOfHours: assignee.noOfHours,
        }));

      const presentAssignees = week.assignees
        .filter((assignee) => !assignee.id.startsWith("temp-"))
        .map((assignee) => ({
          id: assignee.id,
          userId: assignee.assigneeId,
          noOfDays: assignee.noOfDays,
          noOfHours: assignee.noOfHours,
        }));

      const updatedAssignees = week.assignees.filter(
        (assignee) => !assignee.id.startsWith("temp-")
      );

      if (newAssignees.length > 0) {
        newAssigneesPayload.push({
          projectId: projectId,
          weekId: week.id,
          assignees: newAssignees,
        });
      }

      if (presentAssignees.length > 0) {
        presentAssigneesPayload.push({
          projectId: projectId,
          weekId: week.id,
          assignees: presentAssignees,
        });
      }

      return {
        ...week,
        assignees: updatedAssignees,
      };
    });

    return { newAssigneesPayload, presentAssigneesPayload, updatedWeeks };
  };

  const handleOpenModal = () => {
    setDiscardModal(true);
  };

  const saveChanges = async () => {
    const { existingWeeks, newWeeks } = separateWeeks();
    const { newAssigneesPayload, presentAssigneesPayload, updatedWeeks } =
      createPayloadForNewAssigneesAndRemoveFromWeeks();

    console.log("new weeks one", deletedAssigneesPayload);

    try {
      if (deletedAssigneesPayload.length > 0) {
        await dispatch(
          deleteAssignees(deletedAssigneesPayload) as any
        ).unwrap();
      }
      if (newWeeks.length > 0) {
        const numericProjectId = Number(projectId);
        await dispatch(
          addProjectWeeks({
            projectId: numericProjectId,
            weeks: newWeeks,
          }) as any
        ).unwrap();
      }
      if (newAssigneesPayload.length > 0) {
        await dispatch(addAssignees(newAssigneesPayload) as any).unwrap();
      }

      if (presentAssigneesPayload.length > 0) {
        await dispatch(
          updateAssignees(presentAssigneesPayload) as any
        ).unwrap();
      }

      queryClient.invalidateQueries("projectWeeks");

      setDiscardModal(false);

      navigate(`/time-estimates/`);
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `Saved your time estimates`,
              subtitle: "",
              description: `Saved changes to ${projectDetails?.projectDetails?.title}`,
            },
          },
        })
      );
      queryClient.invalidateQueries("projectDetails");
      queryClient.invalidateQueries("tenantProjects");
    } catch (error) {
      console.error("Error during saveChanges:", error);
    }
  };

  const addAndDuplicate = () => {
    const lastWeek =
      weeksWithAssigneesAndNames[weeksWithAssigneesAndNames.length - 1];

    const newStartDate = new Date(lastWeek.endDate);
    newStartDate.setDate(newStartDate.getDate() + 3);

    if (newStartDate > projectEndDate) {
      console.warn(
        "Cannot add a new week: new start date exceeds project end date."
      );
      return;
    }

    const newEndDate = new Date(newStartDate);
    const daysUntilFriday = (5 - newStartDate.getDay() + 7) % 7;
    newEndDate.setDate(newStartDate.getDate() + daysUntilFriday);

    if (newEndDate > projectEndDate) {
      newEndDate.setTime(projectEndDate.getTime());
    }

    const newWeekNumber = getWeekNumber(newStartDate);
    const newWeek = {
      id: `temp-${Date.now()}`,
      week: `Week ${newWeekNumber}`,
      startDate: newStartDate.toISOString(),
      endDate: newEndDate.toISOString(),
      totalHours: lastWeek.totalHours,
      assignees: [...lastWeek.assignees],
    };

    setWeeksWithAssigneesAndNames([...weeksWithAssigneesAndNames, newWeek]);

    const nextStartDate = new Date(newEndDate);
    nextStartDate.setDate(nextStartDate.getDate() + 3);

    if (newEndDate >= nextStartDate || nextStartDate > projectEndDate) {
      setAreButtonsVisible(false);
      console.warn(
        "disabled the add weeks and add and duplicates due to date overlap"
      );
      return;
    }
  };

  const calculateRemainingWeeks = (weeksWithDetails, projectEndDate) => {
    const currentDate = new Date();
    console.log(weeksWithDetails, "weeksWithDetails");
    const endDate = new Date(projectEndDate);

    const lastWeek = weeksWithAssigneesAndNames.reduce((latest, week) => {
      const weekEndDate = new Date(week.endDate);
      return weekEndDate > latest ? weekEndDate : latest;
    }, new Date(0));

    const remainingWeeks = weeksWithDetails.filter((week) => {
      const weekEndDate = new Date(week.endDate);
      const isInRange = weekEndDate > lastWeek && weekEndDate <= endDate;
      return isInRange;
    });

    // Helper function to get the formatted week name with the year
    const formatWeekWithYear = (weekName, weekEndDate) => {
      const year = new Date(weekEndDate).getFullYear();
      const shortYear = String(year).slice(-2);
      return `${weekName} '${shortYear}`;
    };

    const firstWeekName =
      remainingWeeks.length > 0
        ? formatWeekWithYear(
            remainingWeeks[0].weekName,
            remainingWeeks[0].endDate
          )
        : null;

    const lastWeekName =
      remainingWeeks.length > 0
        ? formatWeekWithYear(
            remainingWeeks[remainingWeeks.length - 1].weekName,
            remainingWeeks[remainingWeeks.length - 1].endDate
          )
        : null;

    return { firstWeekName, lastWeekName };
  };

  const switchWeeks = (currentWeekId, selectedWeekId) => {
    console.log(
      currentWeekId,
      selectedWeekId,
      "updateWeeks",
      weeksWithAssigneesAndNames
    );
    const currentWeekIndex = weeksWithAssigneesAndNames.findIndex(
      (week) => week.id === currentWeekId
    );
    const selectedWeekIndex = weeksWithAssigneesAndNames.findIndex(
      (week) => week.id === selectedWeekId
    );

    if (currentWeekIndex === -1 || selectedWeekIndex === -1) {
      console.warn("Invalid week IDs provided.");
      return;
    }

    const currentWeek = weeksWithAssigneesAndNames[currentWeekIndex];
    const selectedWeek = weeksWithAssigneesAndNames[selectedWeekIndex];

    const currentWeekData = {
      ...currentWeek,
      week: selectedWeek.week,
    };

    const clearedSelectedWeek = {
      ...selectedWeek,
      totalHours: 0,
      assignees: [],
    };

    const updatedWeeks = [...weeksWithAssigneesAndNames];
    updatedWeeks[currentWeekIndex] = clearedSelectedWeek;
    updatedWeeks[selectedWeekIndex] = currentWeekData;
    console.log(updateWeeks, "updateWeeks");
    setWeeksWithAssigneesAndNames(updatedWeeks);
  };

  const handleWeekSwitch = (currentWeekId, selectedWeekId) => {
    switchWeeks(currentWeekId, selectedWeekId);
  };

  const { data: projectsData, isLoading: isLoadingProject } =
    useGetTenantProjects();
  const projectList = projectsData?.projects;

  const filteredProjects = projectList?.filter((project) =>
    project.title.toLowerCase().includes(search.toLowerCase())
  );

  const getTitle = () => {
    if (
      projectDetails &&
      projectDetails.projectDetails &&
      projectDetails.projectDetails.title
    ) {
      return projectDetails?.projectDetails?.title;
    } else {
      return "All Projects";
    }
  };

  return (
    <CustomLayout
      page="time-estimates"
      title={
        <div className="flex gap-x-1.5 items-center ">
          <span className="out-300-14 text-gray-500">Time Estimates</span>{" "}
          <span className="out-300-14 text-gray-500">/</span>
          <Popover
            open={isActive}
            placement="bottomLeft"
            onOpenChange={(open) => {
              setSearch("");
              setIsActive(open);
            }}
            content={
              <div className="w-[400px] shadow-md rounded-md bg-white border border-gray-100">
                <div className="p-2 px-4">
                  <CustomSearchBox
                    placeholder="Search Projects"
                    className={`${
                      isFocused ? "border-[#2E90FA]" : "border-gray-300"
                    }`}
                    search={search}
                    setSearch={setSearch}
                    suffix={
                      search ? (
                        <span
                          onClick={() => setSearch("")}
                          className="cursor-pointer"
                        >
                          <CrossIconSecondary />
                        </span>
                      ) : null
                    }
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                </div>

                <div className="max-h-[200px] overflow-y-auto"></div>

                <div className="flex h-[255px] flex-col items-start self-stretch">
                  <div className="w-full max-h-full overflow-y-auto">
                    {filteredProjects?.length ? (
                      filteredProjects?.map((project) => (
                        <ProjectDropDown
                          key={project.id}
                          project={project}
                          onSelect={() => setIsActive(false)}
                        />
                      ))
                    ) : (
                      <div className="p-4 text-center text-gray-500">
                        No projects found
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="w-full py-3 px-4 flex justify-between items-center"
                  style={{
                    borderTop: "1px solid var(--Gray-200, #DDE5ED)",
                    background: "var(--White, #F9FBFE)",
                  }}
                >
                  <div
                    className="text-primary-600 font-outfit text-sm font-medium leading-5 cursor-pointer"
                    onClick={() => navigate(`/time-estimates/`)}
                  >
                    View all projects
                  </div>
                </div>
              </div>
            }
            arrow={false}
            trigger={["click"]}
          >
            <span
              className="flex gap-1 items-center cursor-pointer"
              onClick={() => setIsActive(!isActive)}
            >
              <span className="out-500-14 text-black ">{getTitle()}</span>{" "}
              <ChevronIcon
                className="cursor-pointer text-black"
                style={{
                  transition: "transform 0.3s ease-in-out 0s",
                  transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </span>
          </Popover>
        </div>
      }
    >
      <div className="w-full h-[54px] flex-shrink-0">
        <div className="w-full h-[54px] flex-shrink-0 border-b border-[#DDE5ED] bg-[#F9FBFE] flex items-center justify-between px-[18px]">
          <div className="flex items-center gap-[8px] mt-[9px] mb-[9px]">
            {/* <div
              className="flex items-start rounded-[8px] hover:bg-[#F5F5F5]"
              role="button"
              onClick={() => navigate(`/time-estimates/`)}
            >
              <div className="flex p-[5px_10px] justify-center items-center gap-[8px] rounded-[4px]">
                <ChevronIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    transform: "rotate(90deg)",
                  }}
                />
                <div className="text-[#292927] text-sm font-medium leading-[20px] font-outfit hover:text-[#F5F5F5]">
                  {getTitle()}
                </div>
              </div>
            </div> */}

            <div
              className="group flex items-center rounded-[8px] hover:bg-gray-100 cursor-pointer"
              role="button"
              onClick={() => navigate(`/time-estimates/`)}
            >
              {/* <div className="flex p-[5px_10px] justify-center items-center gap-[8px] rounded-[4px] relative hover:p-[7px_10px]"> */}
              <div className="flex h-[30px] justify-center  py-[3px] px-2 gap-x-1 rounded cursor-pointer hover:border hover:border-[#F5F5F5]">
                <ChevronIcon
                  className="text-[#292927] group-hover:text-gray-500 transition-all duration-300"
                  style={{
                    width: "20px",
                    height: "20px",
                    transform: "rotate(90deg)",
                  }}
                />
                <div
                  className="text-[#292927] text-sm font-medium leading-[20px] font-outfit group-hover:text-gray-500 
                  transition-all duration-300"
                >
                  {getTitle()}
                </div>
                <div
                  className="absolute bottom-0 left-0 right-0 h-[2px] bg-[#F5F5F5] opacity-0 group-hover:opacity-100
                  transition-all duration-300 transform group-hover:translate-y-[-4px]"
                />
              </div>
            </div>

            <div className="flex items-center gap-[8px]">
              <div className="flex w-[75px] justify-end items-center">
                <div
                  className={`flex px-2 py-0 justify-center items-center gap-1 flex-1 rounded-full ${
                    projectDetails?.projectDetails?.isEnded === 0
                      ? "bg-[#D1E9FF]"
                      : "bg-[#D1FFD1]"
                  }`}
                >
                  <div
                    className={`text-${
                      projectDetails?.projectDetails?.isEnded === 0
                        ? "[#165ABF]"
                        : "[#4CAF50]"
                    } text-[12px] font-medium leading-[18px] text-center font-outfit`}
                  >
                    {projectDetails?.projectDetails?.isEnded === 0
                      ? "Ongoing"
                      : "Completed"}
                  </div>
                </div>
              </div>

              <div className="text-[#667085] text-[10px] font-normal leading-normal font-outfit">
                {weeksWithDetails.length} weeks
              </div>
              <div className="w-[1px] h-[8px] bg-[#C0CBD7]"></div>
              <div className="text-[#667085] text-[10px] font-normal leading-normal font-outfit">
                {projectStartDate?.toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
                {" - "}
                {projectEndDate?.toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </div>
              <div
                className="flex w-[14px] h-[14px] justify-center items-center"
                role="button"
                // onClick={() => setProjectDateModal(true)}
              >
                <Tooltip
                  title={"Edit dates"}
                  placement="bottom"
                  style={{
                    display: "flex",
                    padding: "4px 8px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                    borderRadius: "4px",
                    background: "#000000",
                    color: "#ffffff",
                  }}
                >
                  <EditThreeIcon
                    style={{ width: "14px", height: "14px" }}
                    onClick={() => setProjectDateModal(true)}
                  />
                </Tooltip>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="flex items-center gap-[8px] ml-auto">
            {!projectDetails?.projectDetails2?.hasTimeEstimates &&
            overallTotalHours === 0 ? (
              <div className="flex items-center">
                <Tooltip
                  title={"Estimates are pending"}
                  placement="bottom"
                  style={{
                    display: "flex",
                    padding: "4px 8px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                    borderRadius: "4px",
                    background: "#000000",
                    color: "#ffffff",
                  }}
                >
                  <AlertIcon height="14px" width="14px" />
                </Tooltip>
              </div>
            ) : null}

            <div className="relative flex items-center">
              {overallTotalHours > 0 && (
                <motion.div
                  className="absolute right-0 z-[1] overflow-hidden flex gap-2"
                  initial={{ x: 0, opacity: 0 }}
                  animate={{ x: -60, opacity: 1 }}
                  transition={{ duration: 2.5 }}
                >
                  {!projectDetails?.projectDetails2?.hasTimeEstimates && (
                    <div className="flex items-center">
                      <Tooltip
                        title={"Estimates are pending"}
                        placement="bottom"
                        style={{
                          display: "flex",
                          padding: "4px 8px",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          alignSelf: "stretch",
                          borderRadius: "4px",
                          background: "#000000",
                          color: "#ffffff",
                        }}
                      >
                        <AlertIcon height="14px" width="14px" />
                      </Tooltip>
                    </div>
                  )}
                  {overallTotalHours > 0 && (
                    <div className=" rounded-xl bg-[#D1E9FF] cursor-default flex p-[4px_8px] items-center gap-2">
                      <p className="text-[#101828] text-center text-xs font-medium leading-[18px] font-outfit whitespace-nowrap">
                        Total estimated hours
                      </p>
                      <p className="text-gray900 text-center text-xs font-medium leading-[18px] font-outfit">
                        {overallTotalHours}h
                      </p>
                    </div>
                  )}
                </motion.div>
              )}

              {/* Save Button */}
              <div className="flex items-start rounded-[8px]">
                <div
                  className="relative z-[1] flex p-[5px_10px] justify-center items-center gap-[8px] rounded-[4px] border border-[#165ABF] bg-[#165ABF] text-white hover:bg-[#0E4DA3] cursor-pointer"
                  onClick={() => {
                    if (!firstWeekName && !lastWeekName) {
                      saveChanges();
                    } else {
                      handleOpenModal();
                    }
                  }}
                >
                  <p className="text-white-custom font-outfit text-sm font-medium leading-sm">
                    Save
                  </p>
                </div>
              </div>
            </div>

            {areButtonsVisible && (
              <div className="flex items-start rounded-md">
                <div
                  className="flex p-1.5 justify-center items-center gap-2 rounded-md border border-[#EFF8FF] bg-[#EFF8FF] cursor-default"
                  onClick={addWeek}
                >
                  <PlusIconBold style={{ width: "20px", height: "20px" }} />
                  <div className="text-[#144EA6] text-sm font-medium leading-5 font-outfit">
                    Add Week
                  </div>
                </div>
              </div>
            )}

            <div className="flex items-start rounded-lg">
              <div
                className="flex p-1.5 justify-center items-center gap-2 rounded-md border border-[#DDE5ED] bg-[#F9FBFE] hover:bg-gray-100 cursor-pointer"
                onClick={() => setCancelModal(true)}
              >
                <div className="text-[#344054] text-sm font-medium leading-5 font-outfit">
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll bg-gray-50">
        {weeksWithAssigneesAndNames.length > 0 ? (
          <>
            {weeksWithAssigneesAndNames.map((week, index) => (
              <div key={week.id}>
                <DisplayWeeks
                  week={week}
                  isLastWeek={index === weeksWithAssigneesAndNames.length - 1}
                  addAssigneeToWeek={addAssigneeToWeek}
                  onDeleteAssignee={handleDeletedAssignees}
                  weeksWithDetails={weeksWithDetails}
                  onSwitchWeeks={handleWeekSwitch}
                  isDeleted={deletedAssigneesPayload.includes(week.id)}
                />
              </div>
            ))}
          </>
        ) : (
          <div key="default-week">
            <DisplayWeeks
              week={createDefaultWeek()}
              isLastWeek={false}
              addAssigneeToWeek={addAssigneeToWeek}
              onDeleteAssignee={handleDeletedAssignees}
              weeksWithDetails={weeksWithDetails}
              isDeleted={true}
              onSwitchWeeks={handleWeekSwitch}
            />
          </div>
        )}

        {areButtonsVisible && (
          <div className=" bg-gray-50">
            {/* Add week */}
            <div className="inline-flex items-start rounded-[8px] mx-[13px] my-[9px]">
              <div
                className="flex py-[5px] px-[10px] justify-center items-center gap-[8px] rounded-[4px] border border-[#DDE5ED] bg-[#F9FBFE] cursor-pointer hover:bg-gray-50 transition-colors duration-300"
                onClick={addWeek}
              >
                <PlusIconBoldBlack
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                ></PlusIconBoldBlack>

                <div
                  className="text-[#344054] text-[14px] font-medium leading-[20px]"
                  style={{ fontFamily: "Outfit, sans-serif" }}
                >
                  Add Week
                </div>
              </div>
            </div>

            {/* Add and Duplicate  */}
            <div className="inline-flex items-start rounded-[8px] my-[9px]">
              <div
                className="flex p-[5px_10px] justify-center items-center gap-[8px] rounded-[4px] cursor-pointer"
                onClick={addAndDuplicate}
              >
                <CopyOutlineIcon
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                ></CopyOutlineIcon>

                <div
                  className="text-[#667085] text-sm font-medium leading-[20px]"
                  style={{ fontFamily: "Outfit, sans-serif" }}
                >
                  Add & Duplicate
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {projectDateModal && (
        <ProjectDateModal
          onCancel={() => {
            setProjectDateModal(false);
          }}
          visible={projectDateModal}
          startDate={
            projectDetails?.projectDetails?.startDate &&
            new Date(
              projectDetails.projectDetails.startDate
            ).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })
          }
          endDate={
            projectDetails?.projectDetails?.endDate &&
            new Date(projectDetails.projectDetails.endDate).toLocaleDateString(
              "en-GB",
              {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }
            )
          }
          onSave={handleDateSave}
        />
      )}

      <EstimatesMissingModal
        onClose={() => setDiscardModal(false)}
        title="Time estimates are missing!"
        description={
          firstWeekName === lastWeekName
            ? `You missed adding the time estimates for <span style="font-weight: 500;"> ${firstWeekName} </span>. Please add the estimates before the sprint begins.`
            : `You missed adding the time estimates for <span style="font-weight: 500;"> ${lastWeekName} to ${lastWeekName} </span> . Please add the estimates before the sprint begins.`
        }
        buttonOneText="I’ll Do It Later"
        buttonTwoText="Add Time Estimates"
        onButtonOneClick={async () => {
          setDiscardModal(false);
          await saveChanges();
        }}
        onButtonTwoClick={() => {
          setDiscardModal(false);
        }}
        visible={discardModal}
      />

      <EstimatesMissingModal
        onClose={() => setCancelModal(false)}
        title="Cancel process"
        description="Are you sure you want to cancel? You will lose all data.                                        "
        buttonOneText="Cancel"
        buttonTwoText="Yes, Proceed"
        onButtonOneClick={async () => {
          setCancelModal(false);
        }}
        onButtonTwoClick={() => {
          navigate(`/time-estimates/`);
          setCancelModal(false);
        }}
        visible={cancelModal}
      />

      <EstimatesMissingModal
        onClose={() => setDateChangeModalOne(false)}
        title="Week numbers may vary!"
        description="You are changing the start date of this project. This will affect the project week numbers & existing time estimates. Are you sure you want to proceed?"
        buttonOneText="Cancel"
        buttonTwoText="Yes, proceed"
        onButtonOneClick={() => {
          setDateChangeModalOne(false);
        }}
        onButtonTwoClick={async () => {
          setProjectStartDate(tempStartDate);
          setProjectEndDate(tempEndDate);
          setDateChangeModalOne(false);
          await handleApiCalls(apiPayload, true);
        }}
        visible={dateChangeModalOne}
      />

      <EstimatesMissingModal
        onClose={() => setDateChangeModalTwo(false)}
        title="Week numbers may vary!"
        description="You are changing the start date of this project. This will affect the project week numbers. <br/> <br/> Do you want to delete existing estimates/weeks or retain existing estimates & delete weeks manually?"
        buttonOneText="Delete Estimates"
        buttonTwoText="Retain Estimates"
        onButtonOneClick={async () => {
          setDateChangeModalThree(true);
          setDateChangeModalTwo(false);
        }}
        onButtonTwoClick={async () => {
          setProjectStartDate(tempStartDate);
          setProjectEndDate(tempEndDate);
          setDateChangeModalTwo(false);
          await handleApiCalls(apiPayload, false);
        }}
        visible={dateChangeModalTwo}
      />

      <EstimatesMissingModal
        onClose={() => setDateChangeModalThree(false)}
        title="Week numbers may vary!"
        description="This action will automatically delete the estimates for the week(s) no longer in the project duration. Project week numbers will be adjusted accordingly."
        buttonOneText="Cancel"
        buttonTwoText="Confirm"
        onButtonOneClick={async () => {
          setDateChangeModalThree(false);
        }}
        onButtonTwoClick={async () => {
          setDateChangeModalThree(false);
          await handleApiCalls(apiPayload, true);
        }}
        visible={dateChangeModalThree}
      />
    </CustomLayout>
  );
};
export default ProjectTimeEstimatesScreen;
