import React, { useMemo, useState } from "react";
import { BarItemProps, ResponsiveBar } from "@nivo/bar";
import { SettingIcon } from "../../Core/svgV2/SettingsIcon";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { Popover, Checkbox, Divider, Tooltip } from "antd";
import { useTooltip } from "@nivo/tooltip";
import { DeleteScheduleIcon } from "../../Core/svgV3/DeleteIcon";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  calculateNiceTickInterval,
  CustomGridLine,
  generateDynamicTickValues,
  roundToNextMaxValue,
} from "./CustomBulletChart";
import { ReportsInfoIcon } from "../../Core/svgV3/InfoIcon";

type BarDatum = {
  max: number;
  id: string;
  tasks: number;
  bugs: number;
};

const formatTime = (timeInHours) => {
  if (timeInHours === 0) return "0h 0m";
  const hours = Math.floor(timeInHours);
  const minutes = Math.round((timeInHours - hours) * 60);
  return `${hours}h ${minutes}m`;
};

const CustomBar: React.FC<
  BarItemProps<BarDatum> & { selectedKeys: string[]; chartType: number }
> = ({ bar, selectedKeys, chartType }) => {
  const { tasks, bugs } = bar.data.data;
  const { x, y, width, height, color } = bar;

  // Determine colors based on chartType
  const tasksBarColor =
    chartType === 4 || chartType === 10 ? "#D1E9FF" : "#D9D6FE";
  const bugsBarColor =
    chartType === 4 || chartType === 10 ? "#2E90FA" : "#7A5AF8";

  // Show ratio if both tasks and bugs are selected
  const showRatio =
    (selectedKeys.includes("tasks") && selectedKeys.includes("bugs")) ||
    (!selectedKeys.includes("tasks") && !selectedKeys.includes("bugs"));

  const ratio = showRatio && tasks && bugs ? (tasks / bugs).toFixed(2) : null;

  // Adjust width and spacing
  const adjustedWidth = showRatio ? 10 : 20;
  const adjustedXTasks = x + width / 2 - 10;
  const adjustedXBugs = showRatio ? x + width / 2 : adjustedXTasks; // Position bugs bar next to tasks bar

  // const cornerRadius = 6;
  const cornerRadius = Math.min(6, height / 2);

  // Path for tasks bar
  const tasksPath = `
    M${adjustedXTasks},${y + cornerRadius} 
    a${cornerRadius},${cornerRadius} 0 0 1 ${cornerRadius},-${cornerRadius} 
    h${adjustedWidth - 2 * cornerRadius} 
    a${cornerRadius},${cornerRadius} 0 0 1 ${cornerRadius},${cornerRadius} 
    v${height - cornerRadius}
    h-${adjustedWidth} 
    z
  `;

  // Path for bugs bar (height proportional to the bugs count)
  const bugsHeight = (bugs / tasks) * height; // Height of bugs bar relative to tasks bar
  // Path for bugs bar (height proportional to the bugs count)
  // Cap cornerRadius to be no larger than half of the bugsHeight
  const adjustedBugsCornerRadius = Math.min(cornerRadius, bugsHeight / 2);

  const bugsPath = `
  M${adjustedXBugs},${y + adjustedBugsCornerRadius + (height - bugsHeight)} 
  a${adjustedBugsCornerRadius},${adjustedBugsCornerRadius} 0 0 1 ${adjustedBugsCornerRadius},-${adjustedBugsCornerRadius} 
  h${adjustedWidth - 2 * adjustedBugsCornerRadius} 
  a${adjustedBugsCornerRadius},${adjustedBugsCornerRadius} 0 0 1 ${adjustedBugsCornerRadius},${adjustedBugsCornerRadius} 
  v${bugsHeight - adjustedBugsCornerRadius} 
  h-${adjustedWidth} 
  z
`;

  // Calculate the highest point (smallest y-coordinate between tasks and bugs)
  const highestPointY = Math.min(
    y, // top of tasks bar
    y + height - bugsHeight // top of bugs bar
  );

  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  // Tooltip handlers
  const handleMouseEnter = (event, value, label) => {
    showTooltipFromEvent(
      <div className="shadow-xl rounded-lg bg-white p-2 flex items-center">
        <span
          style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor: label === "Tasks" ? tasksBarColor : bugsBarColor,
            borderRadius: "50%",
            marginRight: "5px",
          }}
        />
        <div>
          {chartType === 4 || chartType === 10 ? (
            <div className="out-300-14 text-black">
              Time on {label}:
              <span className="out-500-14 text-black ml-1">
                {formatTime(value || 0)}
              </span>
            </div>
          ) : (
            <div className="out-300-14 text-black">
              No. of {label}:{" "}
              <span className="out-500-14 text-black ml-1">{value}</span>
            </div>
          )}
        </div>
      </div>,
      event
    );
  };

  const handleMouseLeave = () => {
    hideTooltip();
  };

  // Set the font size for the text inside the bar
  const fontSize = 10;

  const hasEnoughHeightForText = (value: number) => {
    // Calculate height based on number of digits
    const numberOfDigits = Math.max(1, value.toString().length);
    const requiredHeight = numberOfDigits * fontSize * 1.5; // Example multiplier for height
    return height >= requiredHeight;
  };

  return (
    <g>
      {/* Case when neither "tasks" nor "bugs" are selected, render both bars */}
      {!selectedKeys.includes("tasks") && !selectedKeys.includes("bugs") && (
        <>
          {/* Tasks bar */}
          {typeof tasks !== "undefined" && tasks > 0 && (
            <>
              <path
                d={tasksPath}
                fill={tasksBarColor}
                onMouseEnter={(e) => handleMouseEnter(e, tasks, "Tasks")}
                onMouseMove={(e) => handleMouseEnter(e, tasks, "Tasks")}
                onMouseLeave={handleMouseLeave}
              />
              {height >= fontSize && ( // Only render text if height is enough for the text
                <text
                  x={adjustedXTasks + adjustedWidth / 2}
                  y={y + height / 2 + 4}
                  fill="black"
                  fontSize={fontSize.toString()}
                  textAnchor="middle"
                  transform={`rotate(-90, ${
                    adjustedXTasks + adjustedWidth / 2
                  }, ${y + height / 2})`}
                  className="cursor-default"
                  onMouseEnter={(e) => handleMouseEnter(e, tasks, "Tasks")}
                  onMouseMove={(e) => handleMouseEnter(e, tasks, "Tasks")}
                  onMouseLeave={handleMouseLeave}
                >
                  {Math.round(tasks)}
                </text>
              )}
            </>
          )}

          {/* Bugs bar */}
          {typeof bugs !== "undefined" && bugs > 0 && (
            <>
              <path
                d={bugsPath}
                fill={bugsBarColor}
                onMouseEnter={(e) => handleMouseEnter(e, bugs, "Bugs")}
                onMouseMove={(e) => handleMouseEnter(e, bugs, "Bugs")}
                onMouseLeave={handleMouseLeave}
              />
              {hasEnoughHeightForText(bugs) && ( // Only render text if bugs bar height is enough for the text
                <text
                  x={adjustedXBugs + adjustedWidth / 2}
                  y={y + (height - bugsHeight) + bugsHeight / 2 + 4} // Center vertically in the bugs bar
                  fill="white"
                  fontSize={fontSize.toString()}
                  textAnchor="middle"
                  transform={`rotate(-90, ${
                    adjustedXBugs + adjustedWidth / 2
                  }, ${y + (height - bugsHeight) + bugsHeight / 2})`}
                  className="cursor-default"
                  onMouseEnter={(e) => handleMouseEnter(e, bugs, "Bugs")}
                  onMouseMove={(e) => handleMouseEnter(e, bugs, "Bugs")}
                  onMouseLeave={handleMouseLeave}
                >
                  {Math.round(bugs)}
                </text>
              )}
            </>
          )}
        </>
      )}

      {/* Tasks bar when selected */}
      {selectedKeys.includes("tasks") &&
        typeof tasks !== "undefined" &&
        tasks > 0 && (
          <>
            <path
              d={tasksPath}
              fill={tasksBarColor}
              onMouseEnter={(e) => handleMouseEnter(e, tasks, "Tasks")}
              onMouseMove={(e) => handleMouseEnter(e, tasks, "Tasks")}
              onMouseLeave={handleMouseLeave}
            />
            {hasEnoughHeightForText(tasks) && (
              <text
                x={adjustedXTasks + adjustedWidth / 2}
                y={y + height / 2 + 4}
                fill="black"
                fontSize={fontSize.toString()}
                textAnchor="middle"
                transform={`rotate(-90, ${
                  adjustedXTasks + adjustedWidth / 2
                }, ${y + height / 2})`}
                className="cursor-default"
                onMouseEnter={(e) => handleMouseEnter(e, tasks, "Tasks")}
                onMouseMove={(e) => handleMouseEnter(e, tasks, "Tasks")}
                onMouseLeave={handleMouseLeave}
              >
                {Math.round(tasks)}
              </text>
            )}
          </>
        )}

      {/* Bugs bar when selected */}
      {selectedKeys.includes("bugs") &&
        typeof bugs !== "undefined" &&
        bugs > 0 && (
          <>
            <path
              d={bugsPath}
              fill={bugsBarColor}
              onMouseEnter={(e) => handleMouseEnter(e, bugs, "Bugs")}
              onMouseMove={(e) => handleMouseEnter(e, bugs, "Bugs")}
              onMouseLeave={handleMouseLeave}
            />
            {bugsHeight >= fontSize && (
              <text
                x={adjustedXBugs + adjustedWidth / 2}
                y={y + (height - bugsHeight) + bugsHeight / 2 + 4}
                fill="white"
                fontSize={fontSize.toString()}
                textAnchor="middle"
                transform={`rotate(-90, ${adjustedXBugs + adjustedWidth / 2}, ${
                  y + (height - bugsHeight) + bugsHeight / 2
                })`}
                className="cursor-default"
                onMouseEnter={(e) => handleMouseEnter(e, bugs, "Bugs")}
                onMouseMove={(e) => handleMouseEnter(e, bugs, "Bugs")}
                onMouseLeave={handleMouseLeave}
              >
                {Math.round(bugs)}
              </text>
            )}
          </>
        )}

      {/* Ratio display (if both tasks and bugs are selected) */}
      {showRatio &&
        ratio !== undefined &&
        +ratio !== Infinity &&
        ratio !== null &&
        !isNaN(+ratio) && (
          <text
            x={(adjustedXTasks + adjustedXBugs + adjustedWidth) / 2 + 10}
            y={highestPointY - 5}
            fill="black"
            fontSize="12"
            textAnchor="middle"
            transform={`rotate(-90, ${
              (adjustedXTasks + adjustedXBugs + adjustedWidth) / 2
            }, ${highestPointY - 10})`}
          >
            {ratio}
          </text>
        )}
    </g>
  );
};

const CustomBarChart: React.FC<{
  data: BarDatum[];
  selectedKeys: string[];
  weeks: number;
  indexByX: string;
  indexByY: string;
  legendTextX: string;
  chartType: number;
  showValues: boolean;
}> = ({
  data,
  selectedKeys,
  weeks,
  indexByX,
  indexByY,
  legendTextX,
  chartType,
  showValues,
}) => {
  const tickRotationBottom = data.length > 4 ? -45 : 0;
  const lastObject = data?.[data.length - 1];
  const max = Math.max(lastObject?.max, 20); // Use max from the last object or fallback to provided maxValue
  const maxValue = max
    ? roundToNextMaxValue(max, calculateNiceTickInterval(max))
    : 20;
  const tickValues = generateDynamicTickValues(maxValue);
  console.log(maxValue, "max", max);

  // Filter out the last object from the data array
  const filteredData = data?.slice(0, -1);
  const isOverflow = filteredData?.length > 45;

  return (
    <div className="relative bg-white flex">
      {/* Left Axis Chart */}
      <div
        className="min-w-[50px] max-w-[50px]"
        style={{
          height: showValues ? "400px" : "452.2px",
        }}
      >
        <ResponsiveBar
          data={filteredData}
          keys={["height"]}
          indexBy={indexByX}
          margin={{ top: 10, right: 0, bottom: 80, left: 20 }}
          padding={0.3}
          layout="vertical"
          colors={{ scheme: "nivo" }}
          axisLeft={{
            tickSize: 0,
            tickValues: tickValues, // Use generated tick values
            tickPadding: -25,
            tickRotation: 0,
            legend: indexByY, // Use the provided legend text
            legendOffset: -8,
            legendPosition: "middle",
          }}
          axisBottom={null}
          enableGridX={false}
          enableGridY={true}
          minValue={0}
          maxValue={maxValue}
          animate={true}
          layers={["axes"]}
          theme={{
            axis: {
              legend: {
                text: {
                  fontFamily: "Outfit",
                  fontSize: 12,
                  fontWeight: 500,
                  textTransform: "capitalize",
                  fill: "#667085",
                },
              },
            },
            text: {
              fontFamily: "Outfit",
              fontSize: 10,
              fontWeight: 500,
              textTransform: "capitalize",
              fill: "#667085",
            },
          }}
        />
      </div>

      {/* Main Chart */}
      <div className={`${isOverflow ? "overflow-x-auto" : "w-full"}`}>
        <div
          className={`${showValues ? "h-[400px]" : "h-[452.2px]"} ${
            isOverflow ? "w-[2300px]" : "w-full"
          }`}
        >
          <ResponsiveBar
            data={filteredData}
            keys={["tasks"]}
            indexBy={indexByX}
            margin={{ top: 10, right: 0, bottom: 80, left: 5 }}
            padding={0.3}
            layout="vertical"
            barComponent={(props) => (
              <CustomBar
                {...props}
                selectedKeys={selectedKeys}
                chartType={chartType}
              />
            )}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: tickRotationBottom,
              format: (v) => {
                const truncatedV = v.toString().substring(0, 10) + "...";
                console.log(truncatedV, "truncatedV");
                return v.length > 10 && data?.length > 4 ? truncatedV : v;
              },
              // legend: legendTextX,
              // legendPosition: "middle",
              // legendOffset: 73,
            }}
            axisLeft={null}
            // axisLeft={{
            //   tickSize: 0,
            //   tickPadding: 5,
            //   tickRotation: 0,
            //   legend: indexByY,
            //   legendPosition: "middle",
            //   legendOffset: -40,
            //   tickValues: tickValues,
            // }}
            layers={[
              "grid",
              "axes",
              // "markers",
              "bars",
              "legends",
              "annotations",
              CustomGridLine(0, "#969696", ""),
            ]}
            minValue={0}
            maxValue={maxValue}
            enableGridX={false}
            enableGridY={true}
            gridYValues={tickValues}
            animate={true}
            theme={{
              axis: {
                legend: {
                  text: {
                    fontFamily: "Outfit",
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "capitalize",
                    fill: "#667085",
                  },
                },
              },
              text: {
                fontFamily: "Outfit",
                fontSize: 10,
                fontWeight: 500,
                textTransform: "capitalize",
                fill: "#667085",
              },
              grid: {
                line: {
                  stroke: "#ccc",
                  strokeWidth: 1,
                  strokeDasharray: "4 4",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CustomGroupedBarChart = ({
  data,
  keys,
  indexByX = "project",
  indexByY = "Numbers",
  heading = "Team time on projects",
  tooltip = "Test",
  showTotalValues = false,
  Total1Text = "Total 1",
  Total2Text = "Total 2",
  weeks = 1,
  heightKey = 21,
  legendTextX = indexByX,
  chartType = 3,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(keys);
  const [isSettingPopoverOpen, setIsSettingPopoverOpen] = useState(false);
  const [isDotsPopoverOpen, setIsDotsPopoverOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { selectedCharts } = useAppSelector((state) => state.dashboard);

  const colorMapping = useMemo(() => {
    const colorScale =
      indexByY === "Numbers" ? ["#D9D6FE", "#7A5AF8"] : ["#D1E9FF", "#2E90FA"];

    return keys.reduce((acc, key, i) => {
      acc[key] = colorScale[i % colorScale.length];
      return acc;
    }, {} as Record<string, string>);
  }, [keys, indexByY]);

  const handleKeyChange = (key) => {
    if (selectedKeys.includes(key)) {
      setSelectedKeys(
        selectedKeys.filter((selectedKey) => selectedKey !== key)
      );
    } else {
      setSelectedKeys([...selectedKeys, key]);
    }
  };

  // Assume total1Key and total2Key are the first two keys from the 'keys' array
  const total1Key = keys[0];
  const total2Key = keys[1];

  // Update the logic to handle the case where neither key is selected
  const filteredData = data.map((item) => {
    const filteredItem = {};
    filteredItem[indexByX] = item[indexByX];

    // If neither key is selected, include both total1Key and total2Key
    if (
      !selectedKeys.includes(total1Key) &&
      !selectedKeys.includes(total2Key)
    ) {
      filteredItem[total1Key] = item[total1Key];
      filteredItem[total2Key] = item[total2Key];
    } else {
      // If some keys are selected, filter based on selected keys
      selectedKeys.forEach((key) => {
        filteredItem[key] = item[key];
      });
    }
    return filteredItem;
  });

  // Calculate total1
  const total1 = filteredData.reduce((sum, item) => {
    // Include total1 if either it's selected or no keys are selected
    if (!selectedKeys.length || selectedKeys.includes(total1Key)) {
      return sum + Number(item[total1Key] || 0);
    }
    return sum;
  }, 0);

  // Calculate total2
  const total2 = filteredData.reduce((sum, item) => {
    // Include total2 if either it's selected or no keys are selected
    if (!selectedKeys.length || selectedKeys.includes(total2Key)) {
      return sum + Number(item[total2Key] || 0);
    }
    return sum;
  }, 0);

  const ratio = total1 && total2 ? (total1 / total2).toFixed(2) : null;

  const popoverContent = (
    <div>
      <div className="flex justify-center py-1 px-2">Display Setting</div>
      <div className="flex flex-col gap-[0.5px]">
        {keys?.map((key) => (
          <div
            key={key}
            className={`py-1 px-2 ${
              selectedKeys.includes(key) ? "bg-blue-100" : "hover:bg-gray-100"
            }`}
          >
            <Checkbox
              checked={selectedKeys.includes(key)}
              onChange={() => handleKeyChange(key)}
            >
              {key}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );

  const popoverContentThreeDots = (
    <div
      className="p-2 flex gap-2 items-center hover:bg-gray-200 cursor-pointer rounded"
      onClick={() => {
        const chartKey = `chart${chartType}`;

        dispatch(
          updateDashboard({
            key: "selectedCharts",
            value: {
              ...selectedCharts,
              [chartKey]: false, // Dynamically updating the correct chart
            },
          })
        );
      }}
    >
      <div>
        <DeleteScheduleIcon className="text-error-600" />
      </div>
      <div className="text-error-600">Delete Chart</div>
    </div>
  );

  const FormattedTime: React.FC<{ time: number }> = ({ time }) => {
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);

    return (
      <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
        {hours > 0 && (
          <>
            {hours}
            <span className="out-500-14 text-gray-500 mx-1">h</span>
          </>
        )}
        {minutes > 0 && (
          <>
            {minutes}
            <span className="out-500-14 text-gray-500 ml-1">m</span>
          </>
        )}
        {hours === 0 && minutes === 0 && (
          <>
            {0}
            <span className="out-500-14 text-gray-500 ml-1">h</span>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-xl">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <div className="out-500-16 text-black">{heading}</div>
          <Tooltip
            title={<div className="">{tooltip}</div>}
            placement={"right"}
          >
            <div>
              <ReportsInfoIcon className=" text-gray-500" />
            </div>
          </Tooltip>
        </div>

        <div className="flex">
          {/* Tooltip and Popover for Settings */}
          <Tooltip title="Display Settings" placement="topRight">
            <Popover
              content={popoverContent}
              trigger="click"
              placement="bottomRight"
              className={`p-[5px] rounded ${
                isSettingPopoverOpen ? "bg-gray-200" : "hover:bg-gray-200"
              }`}
              arrow={false}
              onOpenChange={(visible) => setIsSettingPopoverOpen(visible)}
            >
              <SettingIcon className="text-black cursor-pointer" />
            </Popover>
          </Tooltip>

          {/* Tooltip and Popover for Dots */}
          <Tooltip title="More actions" placement="topRight">
            <Popover
              content={popoverContentThreeDots}
              trigger="click"
              placement="bottomRight"
              className={`p-[5px] rounded ${
                isDotsPopoverOpen ? "bg-gray-200" : "hover:bg-gray-200"
              }`}
              arrow={false}
              onOpenChange={(visible) => setIsDotsPopoverOpen(visible)}
            >
              <DotsVerticalIcon className="text-gray-500" />
            </Popover>
          </Tooltip>
        </div>
      </div>

      {showTotalValues && (
        <div className="flex items-center gap-4 h-[52.2px]">
          {/* If neither total1Key nor total2Key is selected, show both Total1 and Total2 */}
          {!selectedKeys.includes(total1Key) &&
            !selectedKeys.includes(total2Key) && (
              <>
                <div className="flex flex-col gap-1">
                  <div className="out-500-10 text-gray-400 uppercase">
                    {Total1Text}
                  </div>
                  <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px] mt-1">
                    {indexByY === "Hours" ? (
                      <FormattedTime time={total1 || 0} />
                    ) : (
                      <>{total1}</>
                    )}
                  </div>
                </div>
                <Divider
                  type="vertical"
                  style={{ height: "40px", margin: "0" }}
                />
                <div className="flex flex-col gap-1">
                  <div className="out-500-10 text-gray-400 uppercase">
                    {Total2Text}
                  </div>
                  <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px] mt-1">
                    {indexByY === "Hours" ? (
                      <FormattedTime time={total2 || 0} />
                    ) : (
                      <>{total2}</>
                    )}
                  </div>
                </div>
              </>
            )}

          {/* Show Total1 only when total1Key is selected */}
          {selectedKeys.includes(total1Key) && (
            <div className="flex flex-col gap-1">
              <div className="out-500-10 text-gray-400 uppercase">
                {Total1Text}
              </div>
              <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px] mt-1">
                {indexByY === "Hours" ? (
                  <FormattedTime time={total1 || 0} />
                ) : (
                  <>{total1}</>
                )}
              </div>
            </div>
          )}

          {/* Show Divider if both Total1 and Total2 are selected */}
          {selectedKeys.includes(total1Key) &&
            selectedKeys.includes(total2Key) && (
              <Divider
                type="vertical"
                style={{ height: "40px", margin: "0" }}
              />
            )}

          {/* Show Total2 only when total2Key is selected */}
          {selectedKeys.includes(total2Key) && (
            <div className="flex flex-col gap-1">
              <div className="out-500-10 text-gray-400 uppercase">
                {Total2Text}
              </div>
              <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px] mt-1">
                {indexByY === "Hours" ? (
                  <FormattedTime time={total2 || 0} />
                ) : (
                  <>{total2}</>
                )}
              </div>
            </div>
          )}

          {/* Show Ratio if both Total1 and Total2 are selected, and ratio is available */}
          {(!selectedKeys.length ||
            (selectedKeys.includes(total1Key) &&
              selectedKeys.includes(total2Key))) &&
            ratio && (
              <>
                <Divider
                  type="vertical"
                  style={{ height: "40px", margin: "0" }}
                />
                <div className="flex flex-col gap-1">
                  <div className="out-500-10 text-gray-400 uppercase">
                    Ratio
                  </div>
                  <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                    {ratio}
                  </div>
                </div>
              </>
            )}
        </div>
      )}

      <CustomBarChart
        data={data}
        selectedKeys={selectedKeys}
        weeks={weeks}
        indexByX={indexByX}
        indexByY={indexByY}
        legendTextX={legendTextX}
        chartType={chartType}
        showValues={showTotalValues}
      />
      <div className={`relative bg-gray-50 rounded py-3 px-1]`}>
        <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 out-500-12 text-[#667085] capitalize">
          {legendTextX}
          <span className="out-500-12 text-[#667085]">s</span>
        </div>
        <div
          className={`h-[${heightKey}px] items-center justify-center flex gap-4`}
          style={{ height: `${heightKey}px` }}
        >
          {Object.keys(colorMapping)?.map((key) => (
            <div
              key={key}
              onClick={() => {
                if (selectedKeys.includes(key)) {
                  setSelectedKeys(
                    selectedKeys.filter((selectedKey) => selectedKey !== key)
                  );
                } else {
                  setSelectedKeys([...selectedKeys, key]);
                }
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                marginBottom: "2px",
                cursor: "pointer",
                opacity: selectedKeys.includes(key) ? 1 : 0.5,
              }}
            >
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: colorMapping[key],
                  borderRadius: "50%",
                }}
              />
              <span className="capitalize">{key}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomGroupedBarChart;
